import React, {SyntheticEvent, useCallback, useEffect, useRef} from 'react';
import {Redirect, Route, RouteComponentProps, Switch, useHistory, useLocation} from "react-router-dom";
import "./styles.scss"
import LoginScreen, {NeededTermsScreen} from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import DesktopScreen from "./screens/DesktopScreen";
import SettingsScreen from "./screens/SettingsScreen";
import {PaymentDoneScreen} from "./screens/PaymentScreen";
import ActivateScreen from "./screens/ActivateScreen";
import ResetChangePasswordScreen from "./screens/ResetChangePasswordScreen";
import {Button, Container, Nav} from "react-bootstrap";
import {AccountInfo, Footer, Header, isDevelopment, UserMenu} from "./lib/Layout";
import {ChangePasswordScreen} from "./screens/ChangePasswordScreen";
import AccountScreen from "./screens/AccountScreen";
import PayedExportsScreen from "./screens/PayedExportsScreen";
import PacketScreen from "./screens/PacketScreen";
import {CookieInfo} from "./lib/CookieInfo";
import {useRecoilValue, waitForAll} from "recoil";
import {useLogoutCallback, userAtom} from "./lib/UserSession";
import {langAtom, msgsAtom} from "./lib/Language";
import {useQueryClient} from "react-query";
import {ueChat, uePageHit} from "./lib/UserCom";
import {Helmet} from "react-helmet";
import type {UserInfo} from "./api";
import type {LanguageData} from "./LangType";
import {useGoogleAnalytics} from "./lib/GoogleAnalytics";
import {useFacebookPixel} from "./lib/FacebookPixel";
import WorkersScreen, {WorkerScreen} from "./screens/WorkersScreen";

export let loginReturnPath=null;

const LoginRoute = (props) => <Route {...props} render={(props: RouteComponentProps) => {
    loginReturnPath=props.location;
    return <Redirect to={LoginScreen.url}/>;
}}/>;

const App = () => {
    const [ user, msgs ]: [ UserInfo, LanguageData ] = useRecoilValue(waitForAll([ userAtom, msgsAtom ]));
    const lang=useRecoilValue(langAtom);
    const location=useLocation();
    const history=useHistory();
    const queryClient = useQueryClient();
    const ueInitialized=useRef(false);

    useGoogleAnalytics();
    useFacebookPixel();
    // UserCom
    useEffect(() => {
        const chat=ueInitialized.current?null:() => ueChat();
        ueInitialized.current=true;

        if(user) uePageHit({ user_id: user.id, email: user.email }).finally(chat);
        else uePageHit({}).finally(chat);
    }, [ user, location.pathname ]);
    // Przewijanie w górę
    useEffect(() => {
        console.log("ScrollListener");
        return history.listen((loc, action) => {
            if(action==="PUSH") window.scrollTo(0,0);
        });
    }, [history]);

    const handleLogout=useLogoutCallback(queryClient);

    const linkFunc=useCallback((to, e: SyntheticEvent) => {
        history.push(to);
        e.preventDefault();
    }, [ history, location ]);

    // console.log("App for: ", window.location, location, loc );
    const helmet=<Helmet titleTemplate={msgs.commons.metaTitleTemplate} defaultTitle={msgs.commons.metaTitle}>
            <html lang={lang}/>
        </Helmet>;

    if (user) {
        return (<>
            {helmet}
            <Header border>
                <div className="ml-auto d-flex">
                    {(user.type!=="Company") && <Button
                        variant="link"
                        href={`/draw`}
                        className="btn-link-animated"
                    >{msgs.commons.menuDraw}</Button>}

                    <AccountInfo link={linkFunc} msgs={msgs} userInfo={user} prefix={isDevelopment ? "" : "/app"}/>
                    <UserMenu  link={linkFunc} msgs={msgs} userInfo={user} prefix={isDevelopment ? "" : "/app"}/>
                    <div>
                        <a
                            href="#"
                            className="btn btn-link btn-link-animated"
                            onClick={handleLogout}
                        >{msgs.commons.buttonLogout}</a>
                    </div>
                </div>
            </Header>
            <Container fluid="xl" className="pt-3 pb-5">
                <Switch location={location}>
                    <Route path={DesktopScreen.url} exact component={DesktopScreen}/>
                    <Route path={SettingsScreen.url} component={SettingsScreen}/>
                    <Route path={PaymentDoneScreen.url} component={PaymentDoneScreen}/>
                    <Route path={AccountScreen.url} component={AccountScreen}/>
                    <Route path={ChangePasswordScreen.url} component={ChangePasswordScreen}/>

                    <Route path={PayedExportsScreen.url} component={PayedExportsScreen}/>
                    <Route path={PacketScreen.url} exact component={PacketScreen}/>

                    <Route path={WorkerScreen.url} exact component={WorkerScreen}/>
                    <Route path={WorkersScreen.url} exact component={WorkersScreen}/>

                    <Route path={RegisterScreen.promoLink} exact render={() => <Redirect to={PacketScreen.promoLink}/>}/>

                    <Route path={[
                        LoginScreen.url, RegisterScreen.link, ResetPasswordScreen.url,
                        NeededTermsScreen.url, ActivateScreen.url, ResetChangePasswordScreen.url
                    ]} render={() => <Redirect to={DesktopScreen.url}/>}/>
                </Switch>
            </Container>
            <CookieInfo/>
            <Footer/>
        </>);
    }
    return <>
        {helmet}
        <Header>
            <Nav.Item>
                <a href="/draw/" className="btn btn-primary">Wypróbuj za darmo</a>
            </Nav.Item>
        </Header>
        <Switch location={location}>
            <Route path={LoginScreen.url} component={LoginScreen}/>
            <Route path={NeededTermsScreen.url} component={NeededTermsScreen}/>
            <Route path={RegisterScreen.url} component={RegisterScreen}/>
            <Route path={ResetPasswordScreen.url} component={ResetPasswordScreen}/>
            <Route path={ActivateScreen.url} component={ActivateScreen}/>
            <Route path={ResetChangePasswordScreen.url} component={ResetChangePasswordScreen}/>

            <LoginRoute path={[
                SettingsScreen.url, AccountScreen.url,
                PayedExportsScreen.url, PacketScreen.url, ChangePasswordScreen.url,
                WorkersScreen.url, WorkerScreen.url
            ]}/>
            <LoginRoute path={DesktopScreen.url} exact/>
        </Switch>
        <CookieInfo/>
    </>;
}

export default App;