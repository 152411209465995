//@flow
import {Column, ColumnInstance, Row, useSortBy, UseSortByColumnProps, useTable} from "react-table";
import {Table} from "react-bootstrap";
import React, {useCallback} from "react";
import {useQuery} from "react-query";
import {QueryFunctionContext} from "react-query";

const empty=[];

export type DataTableProps<T> = {
    columns: Array<Column<T>>,
    data: Array<T>,
    onRowClick?: (row: T) => void,
    sortable?: boolean,
}

export default function DataTable<T>({ columns, data, onRowClick, sortable }: DataTableProps<T>) {
    let plugins=[];
    if(sortable===true) plugins.push( useSortBy );
    const table = useTable({
        columns,
        data: Array.isArray(data)?data:empty,
    },...plugins);
    return <Table hover={!!onRowClick} striped className={sortable?"table-sortable":undefined}>
        <thead>
        <tr>
            {table.columns.map((column: ColumnInstance & UseSortByColumnProps) => <th
                {...column.getHeaderProps(sortable?column.getSortByToggleProps({ className: column.className }):{ className: column.className })}
            >{column.render('Header')}
                {sortable?<span>{column.isSorted? column.isSortedDesc? ' ▼': ' ▲': (column.canSort?' ▣':'')}</span>:null}
            </th>)}
        </tr>
        </thead>
        <tbody {...table.getTableBodyProps()}>
        {table.rows.map((row:Row<T>, i) => {
            table.prepareRow(row);
            return <tr
                onClick={onRowClick?(() => onRowClick(row.original)):undefined}
                className={onRowClick?"c-pointer ":null}
                {...row.getRowProps()}
            >{row.cells.map((cell) => <td {...cell.getCellProps({ className: cell.column.className })}>{cell.render('Cell')}</td>)}
            </tr>;
        })}
        </tbody>
    </Table>;
}

/**
 * Opakowanie na funkcję zapytania o dane, aby nie przekazywało danych z React-Query
 */
export function useSimpleQueryWrapper<T>(queryFunc: () => Promise<T>): (context: QueryFunctionContext) => Promise<T> {
    // return new Promise((resolve, reject) => {
    //     queryFunc().then(resolve).catch(reject);
    // })
    return useCallback(async (context: QueryFunctionContext) => {
        return await queryFunc();
    }, [queryFunc] );
}

export function QueryDataTable<T>({ queryKey, queryFn, queryOptions, ...props }: $Diff<DataTableProps<T>, { data: any }> & {
    queryKey: any,
    queryFn: () => Promise,
    queryOptions?: any
} ){
    const wrappedFunc=useSimpleQueryWrapper(queryFn);
    const { data } = useQuery(queryKey, wrappedFunc, queryOptions);
    return <DataTable data={data} {...props}/>;
}