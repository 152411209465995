import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {MutableSnapshot, RecoilRoot} from "recoil";
import {initializeLanguage} from "./lib/Language";
import Router from "./lib/Router";
import {QueryClient, QueryClientProvider} from "react-query";
import {initGa} from "./lib/GoogleAnalytics";

initGa();

export const queryClient=new QueryClient({
    defaultOptions: {
        keepPreviousData: true,
        staleTime: 60000,
        cacheTime: 15000,
        refetchOnMount: false,
        queries: {
            retry: 2,
            retryDelay: attemptIndex => attemptIndex<2?3000:30000,
        }
    }
});

ReactDOM.render(
  <React.StrictMode>
      <RecoilRoot initializeState={(state: MutableSnapshot) => initializeLanguage(state, "commons,account")}>
          <React.Suspense fallback={null}>
              <QueryClientProvider client={queryClient}>
                  <Router>
                      <App />
                  </Router>
              </QueryClientProvider>
          </React.Suspense>
      </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

