//@flow
// Dedykowany komponent do obsługi pola input, jako grafika
import {useMsgs} from "./Language";
import {useCallback, useContext, useRef} from "react";
import {useField} from "formik";
import {readAndCompressImage} from "browser-image-resizer";
import {useDropzone} from "react-dropzone";
import {Button, Form as BForm, InputGroup} from "react-bootstrap";
import {GroupContext} from "./Form";

/**
 * Obrazek w postaci data url
 */
const ImagePlaceholder='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAADiAQMAAACbR4sYAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TpSIVBzuIOGSo4mBBVEQ3rUIRKoRaoVUHk0u/oElDkuLiKLgWHPxYrDq4OOvq4CoIgh8gTo5Oii5S4v+SQosYD4778e7e4+4dINTLTLM6xgBNt81UIi5msqti6BUh9COMGYzIzDLmJCkJ3/F1jwBf72I8y//cn6NHzVkMCIjEs8wwbeIN4qlN2+C8TxxhRVklPiceNemCxI9cVzx+41xwWeCZETOdmieOEIuFNlbamBVNjXiSOKpqOuULGY9VzluctXKVNe/JXxjO6SvLXKc5iAQWsQQJIhRUUUIZNmK06qRYSNF+3Mc/4PolcinkKoGRYwEVaJBdP/gf/O7Wyk+Me0nhOND54jgfQ0BoF2jUHOf72HEaJ0DwGbjSW/5KHZj+JL3W0qJHQO82cHHd0pQ94HIH6H8yZFN2pSBNIZ8H3s/om7JA3y3Qveb11tzH6QOQpq6SN8DBITBcoOx1n3d3tff275lmfz/H5XLJH1XlPAAAAAZQTFRFaGJVxMzfuWSG3gAAAAF0Uk5TAEDm2GYAAAABYktHRACIBR1IAAAACXBIWXMAACylAAAspQFJjjI5AAAAB3RJTUUH5AcYDQEVGpIWrQAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAHFSURBVFjD7ddBkoMgEAXQn3Lh0iN4FI8mR+MoHMGlC4vMKOqA8m1CkkkyY7a8Sv9GYhpchQ9OcIITvAEwQHUIABRHoP8GaA6AGUF1ANQISg7suL4JEYBhAuCgc6ClwDjQUKAdqClQDlQUuPWwT+y63PSJfZe4MNDPAAx0C2gkUBNgFlARoBHp8yagENmIm8CyHuzUQ4FdAX4BtJ8LhhOcIBNcT/Ba0EnA/IAqD+hPAOa9QP2HQfdI0OSB/gbwrLecTZ9AxBmmeBpQybPcC4FOHlnvB3UuMMlAHN0b6XbQ5oJBul9YCVylK8zyOIt8oBO/oTzBvwDStbqXzmQnnWrxtykCnQyy3zDqDQCkF2k6KDOBTQeFNDZfxLE5PsspxGogNh34hw6xCv5mI1bBbxSRe3tQA9EK3rFEtILXKOIVfhrFfogKQ2A/lochsH3U2xCIbGMQAtEmvRAgEdbdBomw7jZIhLUGWISlBliEpQZYhOVkgkaY/+NBI8whQCPMmwkawTVqETkLfogJ9AzUMzAMVDPQDBQzYOvjVo2AZgQcGDhoJ9Bz0Eyg46CegJGA5qBKA4qDcgK4AxQjsPeAi1TCgcPPZ4AvXOMwJTO+dA4AAAAASUVORK5CYII=';
const PNGDataURLPrefix='data:image/png;base64,';
const JPEGDataURLPrefix='data:image/jpeg;base64,';


/**
 * Pole typu tekst, które obsługuje zdjęcie w postaci Base64.
 */
const Base64Image = ({png, quality, maxWidth, maxHeight, width, height, readonly, imgClassName, ...props}: {
        png?: boolean, quality?: number, maxWidth: number, maxHeight: number,
        width: string|number, height: string|number, imgClassName?: string,
    }) => {
    const msgs = useMsgs();
    const fileInput = useRef<HTMLInputElement>();
    const context = useContext(GroupContext);
    const [field, meta, helpers] = useField(context.name);
    const error = meta.error && meta.touched;

    const handleUpload = useCallback((files: FileList) => {
        let file = null;
        for (let i = 0; i < files.length; ++i) {
            const {name, type, size} = files[0];
            if (type === "image/jpeg" || type === "image/png") {
                file = files[0];
                break;
            }
        }
        if (file === null) return;

        const config = {
            quality: typeof (quality) === 'number' ? quality : 0.92,
            maxWidth: maxWidth,
            maxHeight: maxHeight,
            autoRotate: true,
            debug: false,
            mimeType: png ? 'image/png' : 'image/jpeg',
        };
        readAndCompressImage(file, config).then(image => {
            let reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onloadend = () => {
                let base64 = reader.result.substr(png ? PNGDataURLPrefix.length : JPEGDataURLPrefix.length);
                helpers.setValue(base64);
                helpers.setTouched(true);
                console.log("Updated photo", base64.length);
            }
        })
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop: handleUpload});

    if (readonly === true) {
        return <InputGroup>
            <img
                src={field.value ? ((png ? PNGDataURLPrefix : JPEGDataURLPrefix) + field.value) : ImagePlaceholder}
                style={{maxWidth: width, maxHeight: height, width: 'auto', height: 'auto', objectFit: 'contain'}}
                className={imgClassName}
            />
        </InputGroup>;
    }

    return <>
        <InputGroup {...getRootProps()} className={isDragActive ? "drag-active" : ""}>
            <img
                src={field.value ? ((png ? PNGDataURLPrefix : JPEGDataURLPrefix) + field.value) : ImagePlaceholder}
                onClick={() => fileInput.current.click()}
                style={{maxWidth: width, maxHeight: height, width: 'auto', height: 'auto', objectFit: 'contain'}}
                className={imgClassName}
            />
        </InputGroup>
        <Button
            variant="secondary"
            disabled={field.value === null}
            onClick={(e) => helpers.setValue(null)}
        >{msgs.commons.removePhoto}</Button>
        {error ? <BForm.Control.Feedback type="invalid">{meta.error}</BForm.Control.Feedback> : null}
        <input
            type="file"
            className="d-none"
            ref={fileInput}
            multiple={false}
            accept="image/png, image/jpeg"
            onChange={(e) => handleUpload(e.target.files)}
        />
    </>
}

export default Base64Image;