//@flow
import React from 'react';
import {RouteComponentProps} from "react-router";
import type {ExportInfo} from "../api";
import {Helmet} from "react-helmet";
import {Column} from "react-table";
import {formatDateTime} from "../lib/DateTime";
import {safeFileName} from "./AccountScreen";
import {userApi} from "../lib/Network";
import DataTable from "../lib/DataTable";

type State = {
    exports: Array<ExportInfo>|null;
}

function noName(name: string|null): string {
    if(name===null || name===undefined || name.length===0) return "Brak nazwy";
    return name;
}

export default class PayedExportsScreen extends React.Component<RouteComponentProps, State> {
    static url="/exports";

    static columns: Array<Column> = [
        {
            Header: 'Data eksportu',
            accessor: 'date',
            Cell: ({ value }) => formatDateTime(value)
        }, {
            Header: 'Nazwa rzutu',
            accessor: 'name'
        }, {
            Header: 'Pobierz',
            accessor: 'id',
            Cell: ({ value, row }) => <>
                <a href={"/export/"+value+"/"+safeFileName(noName(row.original.name))+".png"}>{noName(row.original.name)+".png"}</a>
            </>
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            exports: null,
        }
    }

    componentDidMount() {
        userApi.getExports().then(exports => {
            this.setState({ exports });
        })
    }

    render() {
        return <>
            <Helmet>
                <title>Wykonane eksporty - Archiplaner</title>
            </Helmet>
            <h1>Wykonane eksporty</h1>
            <DataTable
                columns={PayedExportsScreen.columns}
                data={this.state.exports}
            />
        </>
    }

}