//@flow
import React, {useCallback, useState} from 'react';
import type {SketchInfo} from "../api";
import {Button, Col, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {Icon} from "../lib/Icon";
import {userApi} from "../lib/Network";
import {useQuery} from "react-query";
import {useSimpleQueryWrapper} from "../lib/DataTable";
import {useUserSession} from "../lib/UserSession";
import Dialog from "../lib/Dialog";
import {queryClient} from "../index";
import {ReadOnlyCopyInput} from "../lib/Components";
import {Redirect} from "react-router-dom";
import WorkersScreen from "./WorkersScreen";

export const Sketch = ({ value, className, md, handleDelete, handleDuplicate, handleShare }: {
    value: SketchInfo,
    className?: string,
    md?: number,
    handleDelete: (sketch: SketchInfo) => void,
    handleDuplicate: (sketch: SketchInfo) => void,
    handleShare: (sketch: SketchInfo) => void,
}) => {
    return <Col md={md || 4} className={className}>
        <div className="saved-sketch">
            <a href={"/draw#"+value.id}>
                <img
                    src={process.env.REACT_APP_SERVER+"/thumbnail/"+value.id+"/thumbnail.png"}
                    alt={value.name} width={240} height={160}
                    onError={(e) => { e.target.onerror=null; e.target.src="/images/thumbnail_pending.png"; }}
                    loading="lazy"
                />
                <span className={(value.name && value.name.length>30)?"small":undefined}>{value.name}</span>
            </a>
            <div>
                <Button variant="danger"
                        title="Usuń rzut"
                        onClick={() => handleDelete(value)}
                ><Icon.Delete/></Button>
                {handleShare && <Button variant="info" title="Udostępnianie" className="sketch-share"
                                        onClick={() => handleShare(value)}
                ><Icon.Share/></Button>}
                <Button variant="info"
                        title="Utwórz duplikat"
                    onClick={() => handleDuplicate(value )}
                ><Icon.Copy/></Button>
            </div>
        </div>
    </Col>;
};

const DesktopScreen = () => {
    const user=useUserSession();
    const dataFunc=useSimpleQueryWrapper(userApi.getSketches);
    const [ share, setShare ]=useState<SketchInfo|null>(null);
    const { data: sketches, refetch } = useQuery("sketches", dataFunc);

    const handleDelete = useCallback((sketch: SketchInfo) => {
        if(window.confirm("Czy jesteś pewien, że chcesz usunąć ten rzut?")) {
            userApi.deleteSketch(sketch.id).then(() => {
                refetch();
            })
        }
    }, [refetch]);

    const handleCopy =useCallback((sketch: SketchInfo) => {
        userApi.copySketch(sketch.id).then((id) => {
            window.location.href="/draw#"+id;
            // this.refresh();
        })
    }, [ ]);
    const handleShare=useCallback((sketch: SketchInfo) => {
        setShare({...sketch});
    });
    const handleCloseShare=useCallback(() => {
        setShare(null);
        queryClient.invalidateQueries("sketches");
    });

    if(user.type==="Company") return <Redirect to={WorkersScreen.link()} push={false}/>
    return <>
        <Helmet>
            <title>Zapisane rzuty - Archiplaner</title>
        </Helmet>
        {!Array.isArray(sketches)?null:(sketches.length===0?<>
            <p>Brak zapisanych rzutów</p>
            <a className="btn btn-primary" href="/draw">Utwórz nowy rzut</a>
        </>:<Row className="sketch-list">
            {sketches.map((i: SketchInfo) => <Sketch
                key={i.id}
                value={i}
                handleDelete={handleDelete}
                handleDuplicate={handleCopy}
                handleShare={user.share?handleShare:null}
            />)}
        </Row>)}
        {share && <Dialog
            variant="info"
            title="Udostępnianie rzutu"
            show={true}
            onHide={handleCloseShare}
            footer={<Button variant="success" onClick={handleCloseShare}>Zamknij</Button>}
        >
            {!share.shareLink?<>
                <p className="text-primary">Rzut nie jest udostępniony.</p>
                <Button onClick={() => {
                    userApi.shareSketch(share.id, true).then(link => setShare({...share, shareLink: link}));
                }}>Udostępnij</Button>
            </>:<>
                <p className="text-primary">Rzut jest udostępniony. Adres pobierania to:<br/>
                    <ReadOnlyCopyInput value={`${window.location.protocol}//${window.location.hostname}/draw#s${share.shareLink}`}/>
                </p>
                <Button onClick={() => {
                    userApi.shareSketch(share.id, false).then(() => setShare({...share, shareLink: null }));
                }}>Przestań udostępniać</Button>
            </>}
        </Dialog>}
    </>;
}
DesktopScreen.url = "/";

export default DesktopScreen;