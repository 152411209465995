//@flow
// Opakowania na React-Router

import {Dropdown} from "react-bootstrap";
import {BrowserRouter, useHistory, useLocation} from "react-router-dom";
import { createLocation } from 'history';

export type Application="Landing"|"Draw"|"Admin"|"Account";

/**
 * Pomocnicza funkcja, która zwraca informację o tym do jakiej aplikacji należy dany adres.
 * @param url
 */
export function detectApplication(url?: string): Application {
    if(typeof(url)!=='string') url=window.location.pathname;
    if(url.startsWith("/draw/") || url==="/draw") return "Draw";
    if(url.startsWith("/admin/") || url==="/admin") return "Admin";
    if(url.startsWith("/account/") || url==="/account") return "Account";
    return "Landing";
}

export const DropDownLink = ({ to, children }) => {
    const location=useLocation();
    const history=useHistory();
    const href=history.createHref(createLocation(to, null, null, location));

    return <Dropdown.Item href={href} onClick={e => {
        history.push(to);
        e.preventDefault();
    }}>{children}</Dropdown.Item>
}

const Router = ({ children }) => {
    let basename=process.env.PUBLIC_URL;
    if(window && window.location) {
        if(window.location.pathname.startsWith("/pl/")) basename="/pl"+process.env.PUBLIC_URL;
        else if(window.location.pathname.startsWith("/en/")) basename="/en"+process.env.PUBLIC_URL;
    }

    return <BrowserRouter basename={basename}>{children}</BrowserRouter>
}

export default Router;

export function gotoUrl(history: History, url: string, now?: string) {
    if(detectApplication(now)!==detectApplication(url)) {
        window.location.pathname=url;
    } else history.push(url);
}