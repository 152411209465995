//@flow
import React, {useState} from 'react';
import { Col, Modal} from "react-bootstrap";
import { Link} from "../lib/Components";
import LoginScreen from "./LoginScreen";
import {RouteComponentProps} from "react-router";
import {Helmet} from "react-helmet";
import {Form} from "../lib/Form";
import {publicApi} from "../lib/Network";
import {useMsgs} from "../lib/Language";


export const ResetPasswordScreen =() => {
    const [reset, setReset] = useState<boolean>(false);
    const msgs=useMsgs();
    return (<>
        <Helmet>
            <title>{msgs.account.titleResetPassword}</title>
        </Helmet>
        <Modal.Dialog>
            <Modal.Header>
                <Modal.Title>{msgs.account.titleResetPassword}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {reset ? <p>
                    Na podany adres została wysłana wiadomość. Proszę sprawdzić pocztę i postępować zgodnie z
                    instrukcją.
                </p> : <Form
                    initialValues={{
                        email: "",
                    }}
                    validate={{
                        email: {required: true, email: true}
                    }}
                    initialStatus={{
                        error: "",
                    }}
                    onSubmit={async (values, helper) => {
                        let res = await publicApi.resetPassword(values.email);
                        if (!Form.setError(helper, res)) {
                            setReset(true);
                        } else {
                        }
                    }}
                >{(formik) => (<>
                    <Form.RowFormMainError/>
                    <Form.RowGroup name="email">
                        <Form.Label column sm={4}>{msgs.account.labelEmail}:</Form.Label>
                        <Col sm={8}>
                            <Form.Email/>
                        </Col>
                    </Form.RowGroup>
                    <Form.Row>
                        <Col md={12} className="text-right">
                            <Form.Submit>{msgs.account.actionResetPassword}</Form.Submit>
                        </Col>
                    </Form.Row>
                </>)}</Form>}
            </Modal.Body>
            <Modal.Footer>
                {msgs.account.gotoLoginLabel} - <Link to={LoginScreen.url}>{msgs.commons.buttonLogin}</Link>
            </Modal.Footer>
        </Modal.Dialog>
    </>);
}
ResetPasswordScreen.url = "/reset";

export default ResetPasswordScreen;
