//@flow
// Plik z kwestiami związanymi ze zgodami w aplikacji

import React from "react";

/**
 * Komponent z treścią zgody dotyczącej regulaminu.
 */
export const TermRulesLabel = () => {
    return <>Oświadczam, że zapoznałem/am się z <a href="/regulamin/" target="_blank">Regulaminem</a> serwisu internetowego archiplaner.pl oraz akceptuję jego postanowienia.</>;
}

/**
 * Komponent z treścią zgody dotyczącej polityki prywatności.
 */
export const TermPrivacyLabel = () => {
    return <>Oświadczam, że zapoznałem/am się z <a href="/polityka-prywatnosci/" target="_blank">Polityką prywatności</a> serwisu internetowego archiplaner.pl oraz akceptuję postanowienia dot. przetwarzania danych osobowych w serwisie.</>;
}

/**
 * Komponent z treścią zgody dotyczącej marketingu.
 */
export const TermMarketingLabel = () => {
    return <>Wyrażam zgodę na otrzymywanie informacji o usługach i produktach Archiplaner sp. z o.o. (poprzez wiadomości, sms, kontakt telefoniczny lub na koncie w serwisie archiplaner.pl).</>;
}

