//@flow
import React from 'react';
import {Col, Modal} from "react-bootstrap";
import {Link} from "../lib/Components";
import LoginScreen from "./LoginScreen";
import {RouteComponentProps} from "react-router";
import {Helmet} from "react-helmet";
import {publicApi} from "../lib/Network";
import {Form} from "../lib/Form";

type State = {
    valid: boolean|null;
    changed: boolean;
}

export default class ResetChangePasswordScreen extends React.Component<RouteComponentProps<{ code: string }>, State> {
    static url="/change_password/:code";

    constructor(props) {
        super(props);
        this.state = {
            valid: null,
            changed: false,
        }
    }

    componentDidMount() {
        publicApi.isValidResetCode(this.props.match.params.code).then(valid => {
            this.setState({ valid });
        });
    }


    render() {
        if(this.state.valid===null) return null;

        return (<>
            <Helmet>
                <title>Reset hasła - Archiplaner</title>
            </Helmet>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>Zmiana hasła</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.changed?<p className="text-success">
                        Hasło zostało zmienione.
                    </p>:(!this.state.valid?<p className="text-danger">
                        Link jest nieaktualny lub niepoprawny.
                    </p>:<Form
                        initialValues={{
                            password: "",
                            repPassword: ""
                        }}
                        validate={{
                            password: { password: true },
                            repPassword: { password: true }
                        }}
                        initialStatus={{
                            error: "",
                        }}
                        onSubmit={async (values, helper) => {
                            let res=await publicApi.changePassword(this.props.match.params.code, values.password);
                            if(res) {
                                helper.setStatus({ error: res });
                            } else {
                                this.setState({ changed: true });
                            }
                        }}
                    >{(formik) => (<>
                        <Form.RowFormError error={formik.status.error}/>
                        <Form.RowGroup name="password">
                            <Form.Label column sm={4}>Nowe hasło:</Form.Label>
                            <Col sm={8}>
                                <Form.Password/>
                            </Col>
                        </Form.RowGroup>
                        <Form.RowGroup name="repPassword">
                            <Form.Label column sm={4}>Powtórz hasło:</Form.Label>
                            <Col sm={8}>
                                <Form.Password/>
                            </Col>
                        </Form.RowGroup>
                        <Form.Row>
                            <Col md={12} className="text-right">
                                <Form.Submit>Zmień hasło</Form.Submit>
                            </Col>
                        </Form.Row>
                    </>)}</Form>)}
                </Modal.Body>
                <Modal.Footer>
                    Powrót do strony logowania - <Link to={LoginScreen.url}>zaloguj się</Link>
                </Modal.Footer>
            </Modal.Dialog>
        </>);
    }
}