//@flow
import React, {useCallback, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useMsgs} from "./Language";

export type DialogVariant="info"|"danger";

const Dialog = ({ variant, title, footer, children, ...props }: {
    variant?: DialogVariant;
    title: string|React$Node;
    footer?: React$Node;
} ) => {
    return <Modal {...props}>
        <Modal.Header className={variant==="info"?"alert-success":"alert-danger"}>
            <Modal.Title>
                {title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {children}
        </Modal.Body>
        {footer?<Modal.Footer>{footer}</Modal.Footer>:null}
    </Modal>
}

export default Dialog;

export const InfoDialog = ({ title, show, onHide, footer, ...props }: {
    title?: string;
    onHide?: () => void,
    footer?: React$Node;
}) => {
    const msgs=useMsgs();
    if(!show) return null;

    return <Dialog
        variant="info"
        title={title || msgs.commons.titleInfo}
        show={true}
        onHide={onHide}
        footer={footer || <Button variant="success" onClick={onHide}>{msgs.commons.buttonOK}</Button>}
        {...props}
    />
}

export const ErrorDialog = ({ title, show, onHide, footer, ...props }: {
    title?: string;
    onHide?: () => void,
    footer?: React$Node;
}) => {
    const msgs=useMsgs();
    if(!show) return null;

    return <Dialog
        variant="danger"
        title={title || msgs.commons.titleError}
        show={true}
        onHide={onHide}
        footer={footer || <Button variant="success" onClick={onHide}>{msgs.commons.buttonOK}</Button>}
        {...props}
    />
}

type DialogStage = {
    message: string|React$Node|null;
    variant: DialogVariant;
    title?: string|React$Node;
    options?: DialogOptions;
}

type DialogOptions = {
    yesCallback?: () => void;
    noCallback?: () => void;
    yesLabel?: string|React$Node;
    noLabel?: string|React$Node;
}

type DialogSetterFunc = (message?: string|React$Node|null, variant?: DialogVariant, options?: $Shape<DialogOptions>) => void;

/** Pomocniczy hook, który zwraca komponent okna, gdy ma być wyświetlony komunikat */
export function useMessageDialog(): [ React$Node|null, DialogSetterFunc ] {
    const msgs=useMsgs();
    const [ dialogState, setDialogState ] = useState<DialogStage|null>(null);
    const setter=useCallback((message: string|React$Node, variant?: DialogVariant, title?: string|React$Node, options?: DialogOptions) => {
        if(message===null || message===undefined) setDialogState(null);
        else {
            setDialogState({
                message: message,
                variant: variant || "info",
                title,
                options
            });
        }
    }, [ ]);

    if(dialogState===null) return [ null, setter ];
    let footer=null;
    if(dialogState.options && (dialogState.options.yesCallback || dialogState.options.noCallback)) {
        const options=dialogState.options;
        footer=<>
            <Button variant="secondary" onClick={() => {
                setDialogState(null);
                if(options.noCallback) options.noCallback();
            }}>{options.noLabel || msgs.commons.buttonNo}</Button>
            <Button variant="primary" onClick={() => {
                setDialogState(null);
                if(options.yesCallback) options.yesCallback();
            }}>{options.yesLabel || msgs.commons.buttonYes}</Button>

        </>
    }
    if(dialogState.variant==="info") {
        return [ <InfoDialog show title={dialogState.title}
                             footer={footer} backdrop
                             onHide={() => setDialogState(null)}
        >{dialogState.message}</InfoDialog>, setter ];
    } else {
        return [ <ErrorDialog show title={dialogState.title}
                              footer={footer} backdrop
                              onHide={() => setDialogState(null)}
        >{dialogState.message}</ErrorDialog>, setter ];
    }
}
