//@flow
import React from 'react';
import {Helmet} from "react-helmet";
import type {ErrorResult, ValidationRules} from "../lib/Validation";
import Validation from "../lib/Validation";
import {Button, Col, InputGroup} from "react-bootstrap";
import type {PaymentData} from "../api";
import {PaymentDataValidation} from "../api";
import {FormikHelpers, FormikProps} from "formik";
import {userApi} from "../lib/Network";
import {Form} from "../lib/Form";
import {useMsgs} from "../lib/Language";
import {atom, selector, useRecoilCallback, useRecoilValueLoadable} from "recoil";
import {userAtom} from "../lib/UserSession";
import {useMessageDialog} from "../lib/Dialog";

export const paymentSettingsAtom=atom<PaymentData>({
    key: "paymentData",
    default: selector({
        key: "paymentDataLoader",
        get: async({ get }) => {
            const user=get(userAtom);
            if(!user || user.type==="CompanyWorker") return null;
            return await userApi.getPaymentData();
        },
    }),
});

export const PaymentForm = ({validate, required, onSubmit, submit, footer, disabled, children }: {
    validate?: ValidationRules,
    onSubmit?: (values: PaymentData, helpers: FormikHelpers) => Promise<void>,
    submit?: string|React$Node,
    required?: boolean,
    disabled?: boolean,
    children?: (values: FormikProps<PaymentData>) => React$Node;
    footer?: (formik: FormikProps<PaymentData>) => React$Node|string;
}) => {
    const { state, contents: paymentData } =useRecoilValueLoadable(paymentSettingsAtom);
    const msgs=useMsgs();
    const [ dialog, setDialog ] = useMessageDialog();

    let submitDef=useRecoilCallback(({ set }) =>async (values, helpers) => {
        let res=await userApi.updatePaymentData(values, required===true);
        if(!Form.setError(helpers, res)) {
            set(paymentSettingsAtom, values);
            setDialog(msgs.account.paymentSettingsStored);
            // window.alert("Dane płatności zostały zapisane.")
            if(typeof(onSubmit)==='function') onSubmit(values);
        }
    }, []);

    if(state!=='hasValue' || !paymentData) return null;

    return [ <Form
        initialValues={paymentData}
        validate={validate!==undefined?validate:PaymentDataValidation}
        onSubmit={onSubmit?onSubmit:submitDef}
    >{(formik) => (<>
        {children?children(formik):null}
        <Form.RowGroup name="invoiceNip">
            <Form.Label column sm={4}>{msgs.account.paymentSettingsTAX}</Form.Label>
            <InputGroup>
                <Form.Text/>
                <InputGroup.Append>
                    <Button variant="outline-primary" disabled={!formik.values.invoiceNip || (formik.values.invoiceNip.length<10)} onClick={(e) => {
                        userApi.getFromBir(formik.values.invoiceNip).then((res:PaymentData) => {
                            if(res==null) {
                                setDialog(msgs.account.paymentSettingsBirNotDownloaded, "danger");
                                // window.alert("Nie udało się pobrać danych dla podanego numeru NIP");
                            } else {
                                formik.setValues(res);
                                setDialog(msgs.account.paymentSettingsBirDownloaded);
                                // window.alert("Pobrano dane z rejestru GUS");
                            }
                        })
                    }}>{msgs.account.paymentSettingsBirDownload}</Button>
                </InputGroup.Append>
            </InputGroup>
        </Form.RowGroup>
        <Form.RowGroup name="invoiceName">
            <Form.Label column sm={4}>{msgs.account.paymentSettingsName}</Form.Label>
            <Form.Text/>
        </Form.RowGroup>
        <Form.RowGroup name="invoiceAddress">
            <Form.Label column sm={4}>{msgs.account.paymentSettingsAddress}</Form.Label>
            <Form.Text/>
        </Form.RowGroup>
        <Form.RowGroup name="invoicePostal">
            <Form.Label column sm={4}>{msgs.account.paymentSettingsPostalCode}</Form.Label>
            <Form.Text/>
        </Form.RowGroup>
        <Form.RowGroup name="invoiceCity">
            <Form.Label column sm={4}>{msgs.account.paymentSettingsCity}</Form.Label>
            <Form.Text/>
        </Form.RowGroup>
        <Form.Row>
            <p className="text-primary">{msgs.account.paymentSettingsHint}</p>
        </Form.Row>
        {footer?footer(formik):null}
        <Form.Row>
            <Col md={6}>
                <Form.Submit disabled={disabled} block>{submit!==undefined?submit:msgs.commons.buttonSave}</Form.Submit>
            </Col>
        </Form.Row>
    </>)}</Form>, dialog ];
};

type PaymentDoneScreenStatus ={
    status: null|boolean|ErrorResult;
}

export class PaymentDoneScreen extends React.Component<void, PaymentDoneScreenStatus> {
    static url = "/payment_done/:packet";
    static link = (packet: string) => "/payment_done/"+packet;

    constructor(props) {
        super(props);
        this.state={
            status: null,
        }
    }

    componentDidMount() {
        userApi.getPaymentStatus().then(res => {
            if(res===null) {
                window.location.href="/app";    // brak płatności
                return;
            }
            this.setState({ status: res });
        })
    }

    render() {
        if(this.state.status===null) return null;
        const error=Validation.getError(this.state.status);
        return <>
            <Helmet>
                <title>Płatność - Archiplaner</title>
            </Helmet>
            <h1>Płatność</h1>
            {error?<h2 className="text-danger">{error}</h2>:(
                this.state.status===true?<>
                    <h3 className="text-success">Płatność zakończona sukcesem.</h3>
                </>:<>
                    <h2 className="text-danger">Brak informacji zwrotnej o dokonanej płatności.</h2>
                    <p className="text-info">Może to oznaczać, iż system jeszcze nie otrzymał płatności lub płatność się nie powiodła.</p>
                </>
            )}
        </>;
    }
}