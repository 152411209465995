//@flow
import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import {Link} from "../lib/Components";
import LoginScreen from "./LoginScreen";
import {Helmet} from "react-helmet";
import type {UserInfo} from "../api";
import type {ErrorResult} from "../lib/Validation";
import {publicApi} from "../lib/Network";
import {Form} from "../lib/Form";
import {useHistory, useParams} from "react-router-dom";

/**
 * Ekran z informacją o aktywacji konta
 */
const ActivateScreen = () => {
    const {id} = useParams<{ id: string }>();
    const [result, setResult] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);
    // const history=useHistory();

    useEffect(() => {
        publicApi.activate(id).then((res: UserInfo | ErrorResult) => {
            if (Form.isError(res)) {
                setResult(true);
                setMessage(res.main);
            } else {
                console.log("Hash: ", window.location.hash);
                // Nie używamy historii, tylko normalne przejście, co spowoduje zalogowanie do sesji.
                if (window.location.hash.length > 2) {
                    window.location.pathname=process.env.PUBLIC_URL+"/packet/"+window.location.hash.substr(1);
                    // history.push({
                    //     pathname: "/packet/"+window.location.hash.substr(1),
                    // });
                } else {
                    window.location.pathname=process.env.PUBLIC_URL+"/";
                    // history.push("/");
                }
            }
        })
    }, [])

    if (result === false) return null;
    return <>
        <Helmet>
            <title>Rejestracja - Archiplaner</title>
        </Helmet>
        <Modal.Dialog>
            <Modal.Header>
                <Modal.Title>Rejestracja</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message ? <p className="text-danger">{message}</p> :
                    <p className="text-success">Konto zostało aktywowane</p>}
            </Modal.Body>
            <Modal.Footer>
                Przej do strony <Link variant="link" to={LoginScreen.url}>logowania</Link>.
            </Modal.Footer>
        </Modal.Dialog>;
    </>
}
ActivateScreen.url="/activate/:id";
export default ActivateScreen;
