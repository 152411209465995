//@flow
import React, {useMemo} from 'react';
import {Col, Modal, Row} from "react-bootstrap";
import {Link} from "../lib/Components";
import RegisterScreen from "./RegisterScreen";
import ResetPasswordScreen from "./ResetPasswordScreen";
import {Helmet} from "react-helmet";
import {Form} from "../lib/Form";
import {rememberMeKey, userAtom} from "../lib/UserSession";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import {loginReturnPath} from "../App";
import {useMsgs} from "../lib/Language";
import {ueEvent} from "../lib/UserCom";
import {TermPrivacyLabel, TermRulesLabel} from "../lib/AppTerms";
import type {NeededTermsInfo, UserInfo} from "../api";
import type {ErrorResult} from "../lib/Validation";
import Validate from "../lib/Validation";
import {RecoilState, useRecoilCallback} from "recoil";
import {publicApi} from "../lib/Network";
import {RegisterFormValidation} from "../api";

function isNeededTerms(v: any): boolean {
    return typeof(v)==='object' && typeof(v['termsChanged'])==='boolean';
}

function processLogin(set: <T>(recoilVal: RecoilState<T>, valOrUpdater: ((currVal: T) => T) | T) => void, res: UserInfo) {
    if(res && res.rememberMeToken) {
        window.localStorage.setItem(rememberMeKey, res.rememberMeToken);
    }
    set(userAtom, res);
    if(typeof (window) !== 'undefined') {
        if(window.gtag) window.gtag({ event: 'login', userId: res.id });
    }
}

function useLoginCallback(): (login: string, password: string) => Promise<UserInfo|ErrorResult|NeededTermsInfo|null> {
    return useRecoilCallback(({ set}) => async(login, password) => {
        const res=await publicApi.login(login, password);
        if(Validate.isError(res)) return res;   // błąd logowania, nie przetwarzamy dalej, zwracamy wynik
        if(isNeededTerms(res)) return res;    // wymagane zgody, więc nie jest kompletne zalogowanie
        processLogin(set, res);
        return res;
    }, []);
}

function useAcceptTerms(): (term1: boolean, term2: boolean ) => Promise<UserInfo|NeededTermsInfo> {
    return useRecoilCallback( ({ set }) => async(term1, term2) => {
        const res=await publicApi.acceptTerms(term1, term2);
        if(Validate.isError(res)) return res;   // błąd akceptacji zgód
        if(isNeededTerms(res)) return res;
        processLogin(set, res);
        return  res;
    }, []);
}


const LoginScreen = () => {
    const msgs=useMsgs();
    const handleLogin=useLoginCallback();
    const history=useHistory();
    return (<>
        <Helmet>
            <title>{msgs.account.titleLogin}</title>
        </Helmet>
        <Modal.Dialog>
            <Modal.Header>
                <Modal.Title>{msgs.account.titleLogin}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    initialValues={{
                        email: "",
                        password: ""
                    }}
                    initialStatus={{
                        error: null,
                    }}
                    onSubmit={async (values, formik) => {
                        let res=await handleLogin(values.email, values.password);
                        if(isNeededTerms(res)) {
                            history.replace({
                                pathname: NeededTermsScreen.url,
                                state: {
                                    terms: res,
                                }
                            });
                            return;
                        }
                        if(!Form.setError(formik, res)) {
                            let path=loginReturnPath;
                            const target=path?path.pathname:"/";
                            console.log("Successful login. Redirecting to:  ", target);
                            ueEvent("archi_logowanie").finally(() => history.push(target));
                        }
                    }}
                >{(formik) => (<>
                    <Form.RowFormMainError/>
                    <Form.RowGroup name="email">
                        <Form.Label column sm={4}>{msgs.account.labelEmail}:</Form.Label>
                        <Col sm={8}>
                            <Form.Email/>
                        </Col>
                    </Form.RowGroup>
                    <Form.RowGroup name="password">
                        <Form.Label column sm={4}>{msgs.account.labelPassword}:</Form.Label>
                        <Col sm={8}>
                            <Form.Password/>
                        </Col>
                    </Form.RowGroup>
                    <Form.Row>
                        <Col md={8}>
                            {msgs.account.noPasswordInfo} <Link to={ResetPasswordScreen.url}>{msgs.account.noPasswordAction}</Link>
                        </Col>
                        <Col md={4} className="text-right">
                            <Form.Submit className="btn-size">{msgs.commons.buttonLogin}</Form.Submit>
                        </Col>
                    </Form.Row>
                </>)}</Form>
            </Modal.Body>
            <Modal.Footer>
                {msgs.account.noAccountInfo} <Link to={RegisterScreen.link}>{msgs.account.noAccountRegister}</Link>
            </Modal.Footer>
        </Modal.Dialog>
        </>
    );
}
LoginScreen.url="/login";
export default LoginScreen;

/**
 * Pomocniczy ekran z wymaganymi zgodami do zalogowania
 */
export const NeededTermsScreen = () => {
    const msgs=useMsgs();
    const handleAccept=useAcceptTerms();
    const history=useHistory();
    const loc=useLocation();
    const terms: NeededTermsInfo = loc && loc.state && loc.state.terms;
    const initial=useMemo(() => {
        if(!terms) return null;
        return {
            term1: terms.term1,
            term2: terms.term2,
        }
    }, [ terms ]);
    if(!terms) return <Redirect to={LoginScreen.url}/>;


    return (<>
            <Helmet>
                <title>{msgs.account.titleLogin}</title>
            </Helmet>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>{msgs.account.titleLogin}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        initialValues={initial}
                        initialStatus={{
                            error: null,
                        }}
                        validate={{
                            term1: RegisterFormValidation.term1,
                            term2: RegisterFormValidation.term2,
                        }}
                        initialTouched={{
                            term1: true,
                            term2: true,
                        }}
                        enableReinitialize={true}
                        onSubmit={async (values, formik) => {
                            let res=await handleAccept(values.term1, values.term2);
                            if(isNeededTerms(res)) {  // logowanie poprawne, ale wymagana akceptacji zgód
                                history.replace({
                                    pathname: NeededTermsScreen.url,
                                    state: {
                                        terms: res
                                    }
                                })
                                return;
                            }
                            if(!Form.setError(formik, res)) {
                                let path=loginReturnPath;
                                const target=path?path.pathname:"/";
                                console.log("Successful login. Redirecting to:  ", target);
                                ueEvent("archi_logowanie").finally(() => history.push(target));
                            }
                        }}
                    >{(formik) => (<>
                        <Form.RowFormMainError/>
                        {terms.termsChanged && <Row>
                            <Col>
                                <h5>Treści regulaminów uległy zmianie. Prosimy o zapoznanie się ze zaktualizowanymi treściami i ich akceptację.</h5>
                            </Col>
                        </Row>}
                        <Form.RowGroup name="term1">
                            <Col sm={12}>
                                <Form.Check><TermRulesLabel/></Form.Check>
                            </Col>
                        </Form.RowGroup>
                        <Form.RowGroup name="term2">
                            <Col sm={12}>
                                <Form.Check><TermPrivacyLabel/></Form.Check>
                            </Col>
                        </Form.RowGroup>
                        <Form.Row>
                            <Col className="text-right">
                                <Form.Submit className="btn-size">{msgs.commons.buttonLogin}</Form.Submit>
                            </Col>
                        </Form.Row>
                    </>)}</Form>
                </Modal.Body>
            </Modal.Dialog>
        </>
    );
}
NeededTermsScreen.url="/login_terms";
