//@flow

import type { ValidationRules } from "./Validation";
import type { ErrorResult } from "./Validation";
export type LangString={
	pl: string,
	en: string,
};

export type AccountType = 'Free'|'Basic'|'Company'|'CompanyWorker';
export type Integration = 'SpotBrowser'|'EstiCRM';
export type UserInfo={
	id: string;
	email: string;
	name: string;
	rememberMeToken: string;
	type: AccountType;
	exports: number|null;
	validity: string;
	accounts: number|null;
	accountsUsed: number|null;
	webPreferences: string;
	integration: Integration;
	admin: boolean|null;
	share: boolean|null;
	promo: boolean|null;
};

export type NeededTermsInfo={
	termsChanged: boolean;
	term1: boolean;
	term2: boolean;
	term3: boolean;
};

export type ContactForm={
	email: string;
	name: string;
	phone: string;
	users: string;
	exports: string;
	time: string;
	message: string;
	terms: boolean;
};

export const ContactFormValidation: ValidationRules = {
	email: { email: true, emailMessage: "Podana warto\u015B\u0107 nie jest poprawnym adresem e-mail!", notEmpty: true, notEmptyMessage: "Pole nie mo\u017Ce by\u0107 puste!", },
	name: { },
	phone: { },
	users: { },
	exports: { },
	time: { },
	message: { },
	terms: { assertTrue: true, assertTrueMessage: "Zaznaczenie zgody jest wymagane!", },
};

export const ContactFormInitial: ContactForm = {
	email: "",
	name: "",
	phone: "",
	users: "",
	exports: "",
	time: "",
	message: "",
	terms: false,
};

export type ExportFormat = 'JPEG'|'PNG';
export type SketchExportTemplateInfo={
	id: string;
	name: string;
	preview: string;
};

export type SketchData={
	data: string;
	name: string;
	id: string;
};

export type TemplateInfo={
	id: string;
	name: string;
	directory: boolean;
};

export type RegisterForm={
	email: string;
	password: string;
	term1: boolean;
	term2: boolean;
	term3: boolean;
	referral: string;
	mode: string;
};

export const RegisterFormValidation: ValidationRules = {
	email: { email: true, emailMessage: "Niepoprawny adres e-mail", notEmpty: true, notEmptyMessage: "Adres e-mail jest wymagany", },
	password: { notEmpty: true, notEmptyMessage: "Has\u0142o nie mo\u017Ce by\u0107 puste!", },
	term1: { assertTrue: true, assertTrueMessage: "Zgoda jest wymagana", },
	term2: { assertTrue: true, assertTrueMessage: "Zgoda jest wymagana", },
	term3: { },
	referral: { },
	mode: { },
};

export const RegisterFormInitial: RegisterForm = {
	email: "",
	password: "",
	term1: false,
	term2: false,
	term3: false,
	referral: "",
	mode: "",
};

export interface PublicAPI {
	acceptTerms(term1: boolean, term2: boolean): Promise<UserInfo|NeededTermsInfo|ErrorResult>;
	activate(id: string): Promise<UserInfo|ErrorResult>;
	canExport(): Promise<boolean>;
	changePassword(code: string, newPassword: string): Promise<string>;
	contact(form: ContactForm): Promise<void|ErrorResult>;
	exportExternal(sketchId: string, state: string, free: boolean, name: string): Promise<any>;
	getExport(state: string, free: boolean, name: string, exportTemplate: string, inFormat: ExportFormat): Promise<string>;
	getExportsCount(): Promise<number|null>;
	getExportTemplates(): Promise<Array<SketchExportTemplateInfo>>;
	getPreview(state: string, exportTemplate: string): Promise<string>;
	getSharedSketch(id: string): Promise<SketchData>;
	getTemplate(id: string): Promise<SketchData>;
	getTemplates(dirId: string): Promise<Array<TemplateInfo>>;
	getUser(token: string): Promise<UserInfo>;
	isLogged(): Promise<boolean>;
	isValidResetCode(code: string): Promise<boolean>;
	login(email: string, password: string): Promise<UserInfo|NeededTermsInfo|ErrorResult>;
	register(form: RegisterForm): Promise<ErrorResult>;
	resetPassword(email: string): Promise<ErrorResult>;
}

export type ChangePasswordData={
	oldPassword: string;
	newPassword: string;
	verPassword: string;
};

export const ChangePasswordDataValidation: ValidationRules = {
	oldPassword: { },
	newPassword: { notEmpty: true, },
	verPassword: { },
};

export const ChangePasswordDataInitial: ChangePasswordData = {
	oldPassword: "",
	newPassword: "",
	verPassword: "",
};

export type AccountInfo={
	type: AccountType;
	exports: number|null;
	validity: string;
};

export type CompanyData={
	companyName: string;
	companyPhone: string;
	companyEmail: string;
	companyAddress: string;
	companyPostal: string;
	companyCity: string;
	companyCountry: string;
	companyLogo: Array<number>;
};

export type ExportInfo={
	id: string;
	date: string;
	name: string;
};

export type PaymentData={
	invoiceName: string;
	invoiceNip: string;
	invoiceAddress: string;
	invoicePostal: string;
	invoiceCity: string;
	invoiceCountry: string;
};

export const PaymentDataValidation: ValidationRules = {
	invoiceName: { },
	invoiceNip: { pattern: "([0-9][ \\-]?){10}", patternMessage: "Niepoprawny numer NIP", },
	invoiceAddress: { },
	invoicePostal: { pattern: "[0-9]{2}-?[0-9]{3}", patternMessage: "B\u0142\u0119dny kod pocztowy", },
	invoiceCity: { },
	invoiceCountry: { },
};

export const PaymentDataInitial: PaymentData = {
	invoiceName: "",
	invoiceNip: "",
	invoiceAddress: "",
	invoicePostal: "",
	invoiceCity: "",
	invoiceCountry: "",
};

export type Packet = 'BasicMonth'|'BasicYear'|'Promo'|'CompanyMonth'|'CompanyYear'|'CompanyUsers'|'Single'|'Packet10';
export type PacketPriceDetails={
	packet: Packet;
	price: string;
	core: string;
	exports: number|null;
	validity: number|null;
	validityMonths: number|null;
	finalValidity: string;
	usersPrice: string;
	minimalUsers: number|null;
	users: number|null;
	additionalUsers: number|null;
	supplementDays: number|null;
	supplementMonths: number|null;
	supplementUsers: number|null;
	supplementUsersPrice: string;
};

export type PaymentInfo={
	id: string;
	exports: number|null;
	price: string;
	currency: string;
	time: string;
	days: number|null;
	number: string;
	packet: Packet;
	users: number|null;
	prevValidity: string;
	prevExports: number|null;
};

export type SketchInfo={
	id: string;
	name: string;
	shareLink: string;
	modified: number;
	created: number;
};

export type TermsData={
	term1: boolean;
	term2: boolean;
	term3: boolean;
};

export type UserBasicData={
	email: string;
	displayEmail: boolean;
	firstName: string;
	lastName: string;
	phone: string;
	photo: Array<number>;
};

export type PaymentDataPacket={
	invoiceName: string;
	invoiceNip: string;
	invoiceAddress: string;
	invoicePostal: string;
	invoiceCity: string;
	invoiceCountry: string;
	product: string;
	users: number|null;
};

export const PaymentDataPacketValidation: ValidationRules = {
	invoiceName: { },
	invoiceNip: { pattern: "([0-9][ \\-]?){10}", patternMessage: "Niepoprawny numer NIP", },
	invoiceAddress: { },
	invoicePostal: { pattern: "[0-9]{2}-?[0-9]{3}", patternMessage: "B\u0142\u0119dny kod pocztowy", },
	invoiceCity: { },
	invoiceCountry: { },
	product: { notEmpty: true, notEmptyMessage: "Prosz\u0119 wybra\u0107 produkt", },
	users: { },
};

export const PaymentDataPacketInitial: PaymentDataPacket = {
	invoiceName: "",
	invoiceNip: "",
	invoiceAddress: "",
	invoicePostal: "",
	invoiceCity: "",
	invoiceCountry: "",
	product: "",
	users: 0,
};

export interface UserAPI {
	changePassword(data: ChangePasswordData): Promise<void|ErrorResult>;
	copySketch(id: string): Promise<string>;
	deleteSketch(id: string): Promise<void>;
	getAccountInfo(): Promise<AccountInfo>;
	getCompanyData(): Promise<CompanyData>;
	getExports(): Promise<Array<ExportInfo>>;
	getFromBir(nip: string): Promise<PaymentData>;
	getPacketPrice(packetName: string, accounts: number|null): Promise<PacketPriceDetails>;
	getPaymentData(): Promise<PaymentData>;
	getPaymentHistory(): Promise<Array<PaymentInfo>>;
	getPaymentStatus(): Promise<boolean|null|ErrorResult>;
	getSketches(): Promise<Array<SketchInfo>>;
	getTermsData(): Promise<TermsData>;
	getUserData(): Promise<UserBasicData>;
	loadSketch(id: string): Promise<SketchData>;
	logout(rememberMeToken: string): Promise<void>;
	removeLogo(): Promise<void>;
	saveSketch(id: string, name: string, data: string): Promise<string>;
	saveUserPreferences(preferences: string): Promise<void>;
	shareSketch(id: string, share: boolean): Promise<string>;
	startPayment(data: PaymentDataPacket): Promise<string|ErrorResult>;
	updateCompanyData(data: CompanyData): Promise<void|ErrorResult>;
	updatePaymentData(data: PaymentData, required: boolean): Promise<void|ErrorResult>;
	updateTerm(term: string, accept: boolean): Promise<boolean|ErrorResult>;
	updateUserData(data: UserBasicData): Promise<void>;
}

export type WorkerDetails={
	id: string;
	email: string;
	firstname: string;
	lastname: string;
	lastLogged: string;
	password: string;
};

export type WorkerInfo={
	id: string;
	email: string;
	firstname: string;
	lastname: string;
	lastLogged: string;
};

export interface CompanyAPI {
	deleteWorker(wId: string): Promise<void>;
	getWorker(wId: string): Promise<WorkerDetails>;
	getWorkers(): Promise<Array<WorkerInfo>>;
	updateWorker(data: WorkerDetails): Promise<ErrorResult>;
}

