//@flow
import React, {useMemo} from 'react';
import {Helmet} from "react-helmet";
import type {PaymentInfo} from "../api";
import {formatDateTime} from "../lib/DateTime";
import {Column} from "react-table";
import PayedExportsScreen from "./PayedExportsScreen";
import {formatString, useMsgs} from "../lib/Language";
import {QueryDataTable} from "../lib/DataTable";
import {userApi} from "../lib/Network";
import {useUserSession} from "../lib/UserSession";

export function safeFileName(name: string): string {
    if(name===null) return null;
    return name.replaceAll('/','_').replace(' ','-');
}


const PaymentHistoryTable = ( { data }: { data: Array<PaymentInfo> } ) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<Column<PaymentInfo>>>(() => {
        return [
            {
                Header: msgs.commons.labelTime,
                accessor: "time",
                className: "date-time",
                Cell: ({ value }) => formatDateTime(value)
            }, {
                Header: msgs.commons.labelValue,
                accessor: "price",
                className: "money",
                Cell: ({ value, row }) => value+" "+row.original.currency,
            }, {
                Header: msgs.commons.labelPacket,
                id: "packet",
                Cell: ({ row }) => {
                    const pi: PaymentInfo=row.original;
                    switch(pi.packet){
                        case "Single":
                            return msgs.account.histSingleExport;
                        case "Packet10":
                            return formatString(msgs.account.histExportPacket, pi.exports);
                        case "BasicMonth":
                        case "BasicYear":
                        case "Promo":
                            return formatString(msgs.account.histUpgrade, msgs.commons.packetTitleBasic, pi.days);
                        case "CompanyMonth":
                        case "CompanyYear":
                            return formatString(msgs.account.histCompanyUpgrade, msgs.commons.packetTitleCompany, pi.days, pi.users);
                        case "CompanyUsers":
                            return formatString(msgs.account.histCompanyUsers, pi.users);
                        default:
                            return "";
                    }
                },
            }, {
                Header: msgs.commons.labelDocument,
                accessor: "id",
                Cell: ({ value, row }) => row.original.number?<a href={"/invoice/"+value+"/"+safeFileName(row.original.number)+".pdf"} target="_blank">{row.original.number}</a>:"",
            }
        ];
    }, [ msgs.commons.language ] );
    return <QueryDataTable columns={columns} queryKey="paymentHistory" queryFn={userApi.getPaymentHistory}/>
}

/**
 * Klasa dla ekranu okna z informacjami o konie, tj.:
 * - informacje o pakiecie,
 * - historia konta,
 * - eksporty?
 */
const AccountScreen = () => {
    const msgs=useMsgs();
    const user=useUserSession();
    return <>
        <Helmet>
            <title>Informacje o koncie - Archiplaner.pl</title>
        </Helmet>
        <h1>Informacje o koncie</h1>
        <h2>Wykonane eksporty</h2>
        <QueryDataTable columns={PayedExportsScreen.columns} queryKey="exports" queryFn={userApi.getExports}/>
        {user.type!=="CompanyWorker" && <>
            <h2>Historia płatności</h2>
            <PaymentHistoryTable/>
        </>}
    </>;
}
AccountScreen.url="/account";
export default AccountScreen;
