//@flow
// Nakładka na bibliotekę date-fns oraz komponenty do czasu na bazie react-datepicker
import {format, parseISO} from 'date-fns';

export function parseDate(str: string|null|undefined): Date|null {
    if(str===null || str===undefined || str==='') return null;
    return parseISO(str);
}

export function formatDate(d: Date|string, f :? string): string|null {
    if(d===null || d===undefined || d==='') return "";
    if(typeof(d)==='string') d=parseDate(d);
    if(typeof(f)!=='string') f='yyyy.MM.dd'
    return format(d, f);
}

export function formatDateTime(d: Date|string, f :? string): string {
    if(d===null || d===undefined || d==='') return "";
    if(typeof(d)==='string') d=parseDate(d);
    if(typeof(f)!=='string') f='yyyy.MM.dd HH:mm'
    return format(d, f);
}

export function formatTime(d: Date|string, f :? string): string {
    if(d===null || d===undefined || d==='') return "";
    if(typeof(d)==='string') d=parseDate(d);
    if(typeof(f)!=='string') f='HH:mm'
    return format(d, f);
}

/**
 * Formatuje czas do postaci zbliżonej do ISO tylko bez strefy czasowej.
 */
export function formatJSON(d: Date|string): string {
    if(d===undefined || d===null || d==='') return null;
    if(typeof(d)==='string') d=parseDate(d);
    return format(d, "yyyy-MM-dd'T'HH:mm:ss");
}

