//@flow
/* eslint-disable react-hooks/rules-of-hooks */
import React, {Ref, useRef} from 'react';
import {Link as RouterLink, useHistory} from "react-router-dom";
import {Breadcrumb as BBreadcrumb, Button, ButtonProps, FormControl, InputGroup} from "react-bootstrap";
import {Icon} from "./Icon";

export const Link = ({children, ...props}) => {
    return <RouterLink
        {...props}
    >{children}</RouterLink>
};

export const RouterButton = ({ children, href, onClick, ...props }: ButtonProps) => {
    const hist=useHistory();

    return <Button
        href={process.env.PUBLIC_URL+href}
        onClick={(e) => {
            e.preventDefault();
            hist.push({
                pathname: href
            });
            if(onClick) onClick(e);
        }}
        {...props}
    >{children}</Button>
}


export const ReadOnlyCopyInput = ({ value, ...props }) => {
    const ref:Ref<HTMLInputElement>=useRef(null);
    return <InputGroup>
        <FormControl
            ref={ref}
            readOnly
            value={value}
            {...props}
        />
        <InputGroup.Append>
            <Button
                variant="outline-primary"
                onClick={() => {
                    ref.current.select();
                    ref.current.setSelectionRange(0, 99999);
                    document.execCommand("copy");
                }}
            ><Icon.Copy/></Button>
        </InputGroup.Append>
    </InputGroup>;
};



export const Breadcrumb=(props) => {
    return <BBreadcrumb>
        <BBreadcrumb.Item href="/">Home</BBreadcrumb.Item>
        <BBreadcrumb.Item href="/">Strona</BBreadcrumb.Item>
    </BBreadcrumb>
}

