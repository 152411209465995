//@flow
import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {FormikBag, FormikHelpers} from "formik";
import type {ChangePasswordData} from "../api";
import {ChangePasswordDataInitial} from "../api";
import {Helmet} from "react-helmet";
import {useMsgs} from "../lib/Language";
import {Form} from "../lib/Form";
import {userApi} from "../lib/Network";
import {useMessageDialog} from "../lib/Dialog";

export const ChangePasswordScreen = () => {
    const [ dialog, setDialog ] = useMessageDialog();
    const msgs=useMsgs();
    return (<>
        <Helmet>
            <title>{msgs.account.changePasswordTitle} - Archiplaner.pl</title>
        </Helmet>
            {dialog}
            <Container>
                <Row>
                    <Col md={6} className="m-auto">
                        <h1>{msgs.account.changePasswordTitle}</h1>
                        <Form
                            initialValues={ChangePasswordDataInitial}
                            onSubmit={async (values: ChangePasswordData, helpers: FormikHelpers) => {
                                let res=await userApi.changePassword(values);
                                if(Form.setError(helpers, res)) return;
                                setDialog(msgs.account.changePasswordDone);
                            }}
                        >{(formik: FormikBag) => <>
                            <Form.RowGroup name="oldPassword">
                                <Form.Label column sm={4}>{msgs.account.changePasswordOldPassword}</Form.Label>
                                <Form.Password/>
                            </Form.RowGroup>
                            <Form.RowGroup name="newPassword">
                                <Form.Label column sm={4}>{msgs.account.changePasswordNewPassword}</Form.Label>
                                <Form.Password/>
                            </Form.RowGroup>
                            <Form.RowGroup name="verPassword">
                                <Form.Label column sm={4}>{msgs.account.changePasswordRepeatPassword}</Form.Label>
                                <Form.Password/>
                            </Form.RowGroup>
                            <Form.RowGroup name="submit">
                                <Form.Submit>{msgs.account.changePasswordAction}</Form.Submit>
                            </Form.RowGroup>
                        </>}</Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
ChangePasswordScreen.url="/password";