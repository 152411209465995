//@flow
import React from 'react';
import {Button, Col, Modal} from "react-bootstrap";
import {Link} from "../lib/Components";
import LoginScreen from "./LoginScreen";
import {RegisterFormInitial, RegisterFormValidation} from "../api";
import {Helmet} from "react-helmet";
import type {RegisterForm} from "../api";
import {RouteComponentProps} from "react-router-dom";
import {Form} from "../lib/Form";
import {publicApi} from "../lib/Network";
import {ueEvent} from "../lib/UserCom";
import {TermMarketingLabel, TermPrivacyLabel, TermRulesLabel} from "../lib/AppTerms";

type State = {
    registered: boolean;
    referralVisible: boolean;
}

export default class RegisterScreen extends React.Component<RouteComponentProps<{ mode: string }>, State> {
    static url = [ "/register/:mode", "/register" ];
    static link = "/register";
    static promoLink="/register/promo";

    initial: RegisterForm;

    constructor(props) {
        super(props);
        this.state = {
            registered: false,
            referralVisible: false,
        };
        this.initial={...RegisterFormInitial};
        if(window.location.hash && window.location.hash.length>0) {
            this.initial.referral=window.location.hash.substring(1);
        }
        // console.log("Mode: ", this.props.match.params.mode);
    }

    render() {
        return (<>
            <Helmet>
                <title>Rejestracja - Archiplaner</title>
            </Helmet>
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>Rejestracja</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.registered?<p>
                        Rejestracja już prawie zakończona. Na podany adres został wysłany e-mail.
                        Proszę kliknąć w link, który jest w e-mailu, w celu dokończenia rejestracji.
                    </p>:
                    <Form
                        initialValues={this.initial}
                        validate={RegisterFormValidation}
                        initialStatus={{

                        }}
                        onSubmit={async (values, helpers) => {
                            let res=await publicApi.register({
                                ...values,
                                mode: this.props.match.params.mode
                            });
                            if(!Form.setError(helpers, res, null)) {
                                // Brak błędu
                                ueEvent("archi_rejestracja").finally();
                                this.setState({ registered: true });
                            } else {
                                if(res.fields && res.fields["referral"]) {
                                    this.setState({ referralVisible: true });
                                }
                            }
                        }}
                    >{(formik) => <>
                        <Form.RowGroup name="email">
                            <Form.Label column sm={4}>Email:</Form.Label>
                            <Col sm={8}>
                                <Form.Email/>
                            </Col>
                        </Form.RowGroup>
                        <Form.RowGroup name="password">
                            <Form.Label column sm={4}>Hasło:</Form.Label>
                            <Col sm={8}>
                                <Form.Password/>
                            </Col>
                        </Form.RowGroup>
                        <Form.RowGroup name="referral">
                            {this.state.referralVisible ?<>
                                <Form.Label column sm={4}>Kod:</Form.Label>
                                <Col sm={8}>
                                    <Form.Text placeholder="Wprowadź kod"/>
                                </Col>
                                </>:<Col className="text-right">
                                <Button
                                    variant="link"
                                    onClick={() => this.setState({ referralVisible: true })}
                                >Mam kod promocyjny</Button>
                            </Col>
                            }</Form.RowGroup>
                        <Form.RowGroup name="term1" className="terms">
                            <Col sm={12}>
                                <Form.Check><TermRulesLabel/></Form.Check>
                            </Col>
                        </Form.RowGroup>
                        <Form.RowGroup name="term2" className="terms">
                            <Col sm={12}>
                                <Form.Check><TermPrivacyLabel/></Form.Check>
                            </Col>
                        </Form.RowGroup>
                        <Form.RowGroup name="term3" className="terms">
                            <Col sm={12}>
                                <Form.Check><TermMarketingLabel/></Form.Check>
                            </Col>
                        </Form.RowGroup>
                        <Form.Row>
                            <Col md={12} className="text-right">
                                <Form.Submit>Rejestruj</Form.Submit>
                            </Col>
                        </Form.Row>
                    </>}</Form>}
                </Modal.Body>
                <Modal.Footer>
                    {this.state.registered?<>
                        Aktywowałeś już konto? <Link to={LoginScreen.url}>Zaloguj się</Link>
                        </>:<>
                    Masz konto? <Link to={LoginScreen.url}>Zaloguj się</Link>
                    </>}
                </Modal.Footer>
            </Modal.Dialog>
        </>);
    }
}
