//@flow
/* eslint-disable react-hooks/rules-of-hooks */
import React, {useContext} from "react";
import type {ErrorResult, ValidationRule} from "./Validation";
import Validate from "./Validation";
import {FormProps} from "react-bootstrap/Form";
import type {FormikConfig, FormikValues} from "formik";
import {Form as FForm, Formik, FormikHelpers, useField, useFormikContext} from "formik";
import {Button, ButtonProps, Col, Form as BForm} from "react-bootstrap";
import {useMsgsGetter} from "./Language";

export { Form as BForm } from 'react-bootstrap';

export const GroupContext=React.createContext({});
export const FormContext=React.createContext({});

export class Form extends React.Component<{ validate?: { [string]: ValidationRule } } & FormProps & FormikConfig<FormikValues>, void> {
    context: {};
    validate: (values: {}) => {};

    constructor(props) {
        super(props);
        this.context={ validate: this.props.validate };
        if(typeof(this.props.validate)==='object' && this.props.validate!==null) {
            this.validate=(values: {}) => {
                // console.log("Validate values: ", values, this.props.validate);
                return Validate.validate(values, this.props.validate);
            }
        }

    }


    render() {
        const { children, inline, validated, className, style, validate, ...props } = this.props;
        return <FormContext.Provider value={this.context}>
            <Formik validate={this.validate} {...props}>{ (formikProps) => (
                <FForm inline={inline} className={className} style={style}>
                    {children(formikProps)}
                </FForm>
            )}</Formik>
        </FormContext.Provider>;
    }

    static Row=BForm.Row;
    static Group=({children, name, ...props}) => {
        if(typeof(name)!=='string') throw new Error("Missing group name");
        const context=React.useMemo(() => ({ name }), [ name ]);
        return <BForm.Group {...props}>
            <GroupContext.Provider value={context}>{children}</GroupContext.Provider>
        </BForm.Group>;
    };
    static Control=({className, children, ...props}) => {
        const context=useContext(GroupContext);
        const [ field, meta ] = useField(context.name);
        const error=meta.error && meta.touched;
        return <>
            <BForm.Control className={error?"is-invalid ":""+(className || "")} {...field} {...props}>{children}</BForm.Control>
            {error?<BForm.Control.Feedback type="invalid">{meta.error}</BForm.Control.Feedback>:null}
        </>
    };

    static Check = ({id, children, ...props}) => {
        const context=useContext(GroupContext);
        const [ field, meta ] = useField({ name: context.name, type: "checkbox" });
        const error=meta.error && meta.touched;
        return <BForm.Check id={id || context.name} custom {...props}>
            <BForm.Check.Input isInvalid={error} type="checkbox" {...field}/>
            <BForm.Check.Label>{children}</BForm.Check.Label>
            {error?<BForm.Control.Feedback type="invalid">{meta.error}</BForm.Control.Feedback>:null}
        </BForm.Check>;
    };

    static RowGroup=({children, ...props}) => <Form.Group as={BForm.Row} {...props}>{children}</Form.Group>;
    static ColGroup=({children, ...props}) => <Form.Group as={Col} {...props}>{children}</Form.Group>;
    static Label=BForm.Label;
    static Submit= ({children, ...props }: ButtonProps) => {
        const formik = useFormikContext();
        return <Button type="submit" {...props} onClick={formik.handleSubmit}>{children}</Button>;
    };

    static FormError = ({ error }) => <h5 className="text-danger">{error?error:" "}</h5>
    static RowFormError = ({ error }) => <Form.Row>
        <Col md={12} className="text-center">
            <h5 className="text-danger">{error?error:" "}</h5>
        </Col>
    </Form.Row>

    static FormMainError=() => {
        const formik=useFormikContext();
        const msg=useMsgsGetter();
        if(formik.status && formik.status.error) {
            return <h5 className="text-danger">{msg(formik.status.error)}</h5>;
        }
        return null;
    }

    static RowFormMainError = () => {
        const formik=useFormikContext();
        const msg=useMsgsGetter();
        if(formik.status && formik.status.error) {
            return <Form.Row><Col md={12} className="text-center"><h5 className="text-danger">{msg(formik.status.error)}</h5></Col></Form.Row>;
        }
        return null;

    }

    static Text = Form.Control;
    static Password = ({...props}) => <Form.Control type="password" {...props}/>
    static Email = ({...props}) => <Form.Control type="email" {...props}/>
    static PostalCode = Form.Control;
    static NIP = Form.Control;
    static Select = ({...props}) => <Form.Control as="select" {...props}/>

    static Info = BForm.Text;

    static Feedback = ({ name, ...props }) => {
        const [ , meta ] = useField(name);
        const error=meta.error && meta.touched;
        return error?<p className="text-danger" {...props}>{meta.error}</p>:null;
    };

    static FormikRef = React.forwardRef((props, ref) => {
        let f=useFormikContext();
        ref.current=f;
        return null;
    });


    static isError = (res: ErrorResult|any): boolean => {
        if(typeof(res)==='object' && res.error===true) return true;
        return false;
    };

    /**
     * Funkcja ustawiająca pola błędów w formiku
     * @param helpers obiekt formika z onSubmit
     * @param res wynik z serwera typu ErrorResult
     * @param status opcjonalna nazwa dla pola z głównym błędem; gdy null nie będzie ustawiany błąd dla pola głównego
     * @return {boolean} czy res był obiektem błędu
     */
    static setError = (helpers: FormikHelpers, res: ErrorResult|any, status?: string): boolean => {
        if(res===null || res===undefined || typeof(res)!=='object' || res.error!==true) return false;    // brak błędu
        // główny błąd
        if(typeof(res.main)==='string') {
            console.log("Main error: ", res.main);
            if(status===null) {
            } else if(status===undefined) {
                helpers.setStatus({ error: res.main });
            } else {
                helpers.setStatus({ [status]: res.main });
            }
        }
        // błędy dla pól
        if(typeof(res.fields)==='object') {
            console.log("Field errors: ", res.fields);
            helpers.setErrors(res.fields);
        }
        return true;
    }
}
