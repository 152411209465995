//@flow
import React, {useCallback, useMemo} from 'react';
import {Helmet} from "react-helmet";
import {companyApi} from "../lib/Network";
import {Button, Col, Row} from "react-bootstrap";
import {QueryDataTable} from "../lib/DataTable";
import {Redirect, useHistory, useParams} from "react-router-dom";
import {Column} from "react-table";
import type {WorkerDetails, WorkerInfo} from "../api";
import {useQuery} from "react-query";
import {BForm, Form} from "../lib/Form";
import {queryClient} from "../index";
import {useUserRefreshCallback, useUserSession} from "../lib/UserSession";

function getWorkerFunc({ queryKey }) {
    return companyApi.getWorker(queryKey[1]);
}


/**
 * Dane pojedynczego konta (widok)
 */
export const WorkerScreen = () => {
    const { id } = useParams<{ id: string }>();
    const { data } = useQuery<WorkerDetails>(["worker", id ], getWorkerFunc);
    const history=useHistory();
    const refreshCallback=useUserRefreshCallback();
    const user=useUserSession();

    const handleDelete=useCallback(() => {
       companyApi.deleteWorker(id).then(() => {
           refreshCallback()
           queryClient.invalidateQueries(["worker", id]);
           queryClient.invalidateQueries(["workers"]);
           history.replace({ pathname: WorkersScreen.link() });
       })
    });
    if(user.type!=="Company") return <Redirect to="/" push={false}/>

    if(!data) return null;

    const isNew=data.id==="-";

    return <>
        <Helmet>
            <title>{data.email}</title>
        </Helmet>
        <h1>Użytkownik: {data.email}</h1>
        <Form
            initialValues={data}
            onSubmit={async (values, helpers) => {
                companyApi.updateWorker(values).then(err => {
                    if(!err) {
                        refreshCallback()
                        queryClient.invalidateQueries(["worker", id]);
                        queryClient.invalidateQueries(["workers"]);
                        history.push(WorkersScreen.link());
                        return;
                    }
                    Form.setError(helpers, err);
                })
            }}
        >{() => <>
            <Form.FormMainError/>
            <Row>
                <Form.ColGroup md={3} name="email">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Email readOnly={!isNew}/>
                </Form.ColGroup>
                <Form.ColGroup md={3} name="firstname">
                    <Form.Label>Imię</Form.Label>
                    <Form.Text/>
                </Form.ColGroup>
                <Form.ColGroup md={3} name="lastname">
                    <Form.Label>Nazwisko</Form.Label>
                    <Form.Text/>
                </Form.ColGroup>
            </Row>
            <Row>
                <Form.ColGroup md={4} name="password">
                    <Form.Label>Hasło</Form.Label>
                    <Form.Password/>
                    {!isNew && <BForm.Text>Wprowadź hasło, aby zresetować.</BForm.Text>}
                </Form.ColGroup>
            </Row>
            <Row>
                <Col>
                    <Form.Submit>{isNew?"Dodaj":"Zapisz"}</Form.Submit>
                </Col>
                <Col>
                    {!isNew && <Button variant="danger" onClick={handleDelete}>Usuń użytkownika</Button>}
                </Col>
            </Row>
        </>}</Form>
    </>;
}
WorkerScreen.url="/users/:id";
WorkerScreen.link=(id: string) => "/users/"+id;

const WorkersScreen = () => {
    const columns = useMemo<Array<Column<WorkerInfo>>>(() => [
        {
            accessor: "email",
            Header: "E-mail",
        }, {
            accessor: "firstname",
            Header: "Imię",
        }, {
            accessor: "lastname",
            Header: "Nazwisko"
        }
    ], []);
    const workersFunction=useCallback(() => companyApi.getWorkers());
    const history=useHistory();
    const user=useUserSession();
    if(user.type!=="Company") return <Redirect to="/" push={false}/>

    return <>
        <Helmet>
            <title>Użytkownicy konta firmowego</title>
        </Helmet>
        <h1>
            Użytkownicy konta firmowego (użycie: {user.accountsUsed}/{user.accounts})
        </h1>
        <QueryDataTable
            columns={columns}
            queryKey={["workers"]}
            queryFn={workersFunction}
            onRowClick={(row) => history.push(WorkerScreen.link(row.id)) }
        />
        <Button
            disabled={user.accountsUsed>=user.accounts}
            onClick={() => history.push(WorkerScreen.link("-"))}
        >Dodaj użytkownika</Button>
    </>;
};
WorkersScreen.url="/users";
WorkersScreen.link = () => WorkersScreen.url;

export default WorkersScreen;