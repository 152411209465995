//@flow
import React, {useCallback} from 'react';
import {Button, Col, Container, Row, Table} from "react-bootstrap";
import type {CompanyData, TermsData, UserBasicData} from "../api";
import {Helmet} from "react-helmet";
import {PaymentForm, paymentSettingsAtom} from "./PaymentScreen";
import {userApi} from "../lib/Network";
import {Form} from "../lib/Form";
import {userAtom} from "../lib/UserSession";
import {useMsgs} from "../lib/Language";
import Base64Image from "../lib/Base64Image";
import {atom, selector, useRecoilCallback, useRecoilValue, waitForAll} from "recoil";
import {useMessageDialog} from "../lib/Dialog";
import {TermMarketingLabel, TermPrivacyLabel, TermRulesLabel} from "../lib/AppTerms";

export const companyDataAtom=atom<CompanyData>({
    key: 'companyData',
    default: selector({
        key: 'companyDataLoader',
        get: async ({ get }) => {
            const user=get(userAtom);
            if(!user || user.type!=="Company") return null;
            return await userApi.getCompanyData();
        }
    }),
});

export const userInfoAtom=atom<UserBasicData>({
    key: 'userInfo',
    default: selector({
        key: 'userInfoLoader',
        get: async ({get}) => {
            const user = get(userAtom);
            if (!user) return null;
            return await userApi.getUserData();
        },
    }),
});

export const termsInfoAtom=atom<TermsData>({
    key: 'termsInfo',
    default: selector({
        key: 'termsInfoLoader',
        get: async ({get}) => {
            const user = get(userAtom);
            if (!user) return null;
            return await userApi.getTermsData();
        },
    }),

})

const TermInfo = ({ value }) => {
    return value?"Wyrażona":"Brak";
}

const TermAction = ({ name, value, onChange }: {
    name: string, value: boolean, onChange: (term: string, accept: boolean) => void
}) => {
    if(value) return <Button variant="secondary" onClick={() => onChange(name, false)}>Wycofaj</Button>
    return <Button variant="success" onClick={() => onChange(name, true)}>Udziel</Button>
}

const SettingsData = () => {
    const [ values, company, payment, terms ] = useRecoilValue(waitForAll([ userInfoAtom, companyDataAtom, paymentSettingsAtom, termsInfoAtom ]));
    const [ dialog, setDialog ] = useMessageDialog();
    const msgs = useMsgs();
    const updateUser=useRecoilCallback(({set}) => async (values, helpers) => {
        let res = await userApi.updateUserData(values);
        if (!Form.setError(helpers, res)) {
            set(userInfoAtom, values);
            setDialog(msgs.account.accountSettingsStored);
            // window.alert("Dane agenta zostały zapisane.");
        }
    }, [ setDialog ]);

    const updateCompany=useRecoilCallback(({ set }) => async (values, helpers) => {
        let res = await userApi.updateCompanyData(values);
        if (!Form.setError(helpers, res)) {
            set(companyDataAtom, values);
            setDialog(msgs.account.companySettingsStored);
            // window.alert("Dane biura zostały zapisane.")
        }
    }, []);

    const changeTerm=useRecoilCallback(({ set }) => async(term, accept) => {
        let res=await userApi.updateTerm(term, accept);
        if(res) {   // wylogowanie
            set(userAtom, null);    // nie zalogowany
            window.location.pathname="/";
            return;
        }
        let newTerms=await userApi.getTermsData();
        set(termsInfoAtom, newTerms);
    }, []);

    const changeTermButton=useCallback((term, accept) => {
        if(accept || term==="term3") {
            changeTerm(term, accept);
            return;
        }
        setDialog(<p>Wycofanie wymaganej zgody uniemożliwi dalszą pracę z aplikacją.<br/>
            Po wycofaniu zgody zostaniesz wylogowany.<br/>
            Czy jesteś pewien, że chcesz wycofać tą zgodę?
            </p>, "danger", "Jesteś pewien?", {
                yesCallback: () => changeTerm(term, accept)
        });
    })

    return <Container>
        <Row>
            <Col md={6} className="mb-4">
                <h1>{msgs.account.accountSettings}</h1>
                <Form
                    initialValues={values}
                    onSubmit={updateUser}
                >{(values) => (<>
                    <Form.RowGroup name="email">
                        <Form.Label column sm={4}>{msgs.account.accountSettingsEmail}</Form.Label>
                        <Form.Text readOnly/>
                    </Form.RowGroup>
                    <Form.RowGroup name="displayEmail">
                        <Form.Check>{msgs.account.accountSettingsDisplayEmail}</Form.Check>
                    </Form.RowGroup>
                    <Form.RowGroup name="firstName">
                        <Form.Label column sm={4}>{msgs.account.accountSettingsFirstname}</Form.Label>
                        <Form.Text/>
                    </Form.RowGroup>
                    <Form.RowGroup name="lastName">
                        <Form.Label column sm={4}>{msgs.account.accountSettingsLastname}</Form.Label>
                        <Form.Text/>
                    </Form.RowGroup>
                    <Form.RowGroup name="phone">
                        <Form.Label column sm={4}>{msgs.account.accountSettingsPhone}</Form.Label>
                        <Form.Text/>
                    </Form.RowGroup>
                    <Form.RowGroup name="photo">
                        <Form.Label column sm={4}>{msgs.account.accountSettingsPhoto}</Form.Label>
                        <Base64Image
                            maxHeight={400} maxWidth={400}
                            height="10em" width="10em" png={false}
                        />
                    </Form.RowGroup>
                    <Form.Row>
                        <Col md={12}>
                            <Form.Submit>{msgs.commons.buttonSave}</Form.Submit>
                        </Col>
                    </Form.Row>
                </>)}</Form>
            </Col>
            {payment?<Col md={6} className="mb-4">
                {/*<hr className="d-sm-none"/>*/}
                <h1>{msgs.account.paymentSettings}</h1>
                <PaymentForm initial={payment}/>
            </Col>:null}
            {company ? <Col md={6} className="mb-4">
                <h1>{msgs.account.companySettings}</h1>
                <Form
                    initialValues={company}
                    onSubmit={updateCompany}
                >{(values) => (<>
                    <Form.RowGroup name="companyName">
                        <Form.Label column sm={4}>{msgs.account.companySettingsName}</Form.Label>
                        <Form.Text/>
                    </Form.RowGroup>
                    <Form.RowGroup name="companyLogo">
                        <Form.Label column sm={4}>{msgs.account.companySettingsLogo}</Form.Label>
                        <Base64Image
                            maxHeight={400} maxWidth={400}
                            height="10em" width="10em" png={true}
                        />
                    </Form.RowGroup>
                    <Form.Row>
                        <Col md={12}>
                            <Form.Submit>{msgs.commons.buttonSave}</Form.Submit>
                        </Col>
                    </Form.Row>
                </>)}</Form>
            </Col> : null}
            {terms && <Col md={6} className="mb-4">
                <h1>Zgody</h1>
                <Table>
                    <thead>
                    <tr>
                        <th>Zgoda</th>
                        <th>Stan</th>
                        <th>Akcja</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><TermRulesLabel/></td>
                        <td><TermInfo value={terms.term1}/></td>
                        <td><TermAction name="term1" value={terms.term1} onChange={changeTermButton}/></td>
                    </tr>
                    <tr>
                        <td><TermPrivacyLabel/></td>
                        <td><TermInfo value={terms.term2}/></td>
                        <td><TermAction name="term2" value={terms.term2} onChange={changeTermButton}/></td>
                    </tr>
                    <tr>
                        <td><TermMarketingLabel/></td>
                        <td><TermInfo value={terms.term3}/></td>
                        <td><TermAction name="term3" value={terms.term3} onChange={changeTermButton}/></td>
                    </tr>
                    </tbody>
                </Table>
            </Col>}
        </Row>
        {dialog}
    </Container>
}

const SettingsScreen = () => {
    const msgs = useMsgs();

    return <>
        <Helmet>
            <title>{msgs.account.accountSettings} - Archiplaner.pl</title>
        </Helmet>
        <React.Suspense fallback={null}><SettingsData/></React.Suspense>
    </>
}
SettingsScreen.url = "/settings";
export default SettingsScreen;

