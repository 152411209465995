//@flow
import React, {useCallback, useEffect, useState} from 'react';
import {Redirect, useHistory, useParams} from "react-router-dom";
import {formatString, useMsgs} from "../lib/Language";
import {Helmet} from "react-helmet";
import type {PacketProduct} from "../lib/Packets";
import Packet, {PacketAction, PacketFooter, PacketPrice, PriceMode} from "../lib/Packets";
import {Col, Row, Spinner, Table} from "react-bootstrap";
import type {AccountType, PacketPriceDetails} from "../api";
import {PaymentForm} from "./PaymentScreen";
import {RouterButton} from "../lib/Components";
import type {ErrorResult} from "../lib/Validation";
import {FormikHelpers} from "formik";
import {formatDate} from "../lib/DateTime";
import {userApi} from "../lib/Network";
import {BForm, Form} from "../lib/Form";
import {useUserSession} from "../lib/UserSession";

const UserPacketInfo = () => {
    const user = useUserSession();
    const msgs = useMsgs();
    switch (user.type) {
        default:
        case "Free":
            return <h2>
                {msgs.account.packetExports + ": "}<span
                className="text-success">{user.exports === 0 ? msgs.account.packetNoExports : user.exports}</span>
                <RouterButton
                    className="float-right"
                    href={PacketScreen.link("free_10_exports")}
                >{msgs.account.packetBuyExports}</RouterButton>
            </h2>
        case "Basic":
            return <h2>
                {msgs.account.packetActiveTo + ": "}<span className="text-success">{formatDate(user.validity)}</span>
                <RouterButton className="float-right" href={PacketScreen.link("basic_month")}>{msgs.account.packetExtend}</RouterButton>
            </h2>
        case "Company":
            return <>
                <h2>
                    {msgs.account.packetActiveTo + ": "}<span className="text-success">{formatDate(user.validity)}</span>
                    <RouterButton
                        className="float-right"
                        href={PacketScreen.link("company_month")}
                    >{msgs.account.packetExtend}</RouterButton>
                </h2>
                <h2>
                    {msgs.account.packetAccounts + ": "}<span className="text-success">{user.accountsUsed + "/" + user.accounts}</span>
                    <RouterButton
                        className="float-right"
                        href={PacketScreen.link("company_users")}
                    >{msgs.account.packetUpgradeAccounts}</RouterButton>
                </h2>
            </>
    }
}

const UserProduct = ( { product, users, onUsersChange, minUsers }: {
    product: PacketProduct,
    users?: number|null, onUsersChange?: (users: number) => void;
    minUsers?: number;
} ) => {
    const user = useUserSession();
    const msgs = useMsgs();
    const history = useHistory();
    const priceMode = product==="company_users"?"users":(product === "basic_month" || product === "company_month") ? "month" : "year";
    const changePriceMode = useCallback((e) => {
        const pm = e.target.value;
        if (priceMode === pm) return;
        history.replace({
            pathname: PacketScreen.link((product === "basic_month" || product === "basic_year") ? ("basic_" + pm) : ("company_" + pm))
        })
    }, [ history, priceMode ]);

    switch (product) {
        default:
        case "free_10_exports":
            return <Packet type="Free">
                <PacketPrice>{msgs.commons.packetItemBuyExports}</PacketPrice>
            </Packet>
        case "basic_month":
        case "basic_year":
            return <Packet type="Basic">
                <PacketPrice>
                    <span>{priceMode === "month" ? "29,00 PLN" : "261,00 PLN"}</span>
                    <PriceMode value={priceMode} onChange={changePriceMode}/>
                </PacketPrice>
                <PacketFooter>{priceMode === "month" ? null : <><s>348,00
                    PLN</s> {formatString(msgs.commons.packetPriceDiscount, 25)}</>}</PacketFooter>
            </Packet>
        case "company_month":
        case "company_year":
        case "company_users":
            return <Packet type="Company">
                <PacketPrice>
                    <span>{priceMode==="users"?"---":(priceMode === "month" ? "99,00 PLN" : "891,00 PLN")}</span>
                    <PriceMode value={priceMode} onChange={changePriceMode} usersOnly={user.type==="Company"}/>
                </PacketPrice>
                <PacketFooter>{priceMode !== "year"? null : <><s>1188,00
                    PLN</s> {formatString(msgs.commons.packetPriceDiscount, 25)}</>}</PacketFooter>
                <PacketPrice>
                    {msgs.account.companySettingsAccounts}: <BForm.Control
                    disabled={users===null}
                    className="ml-2" type="number" min={minUsers} max={999}
                    style={{ width: "5em", display: "inline-block" }}
                    value={users || ""}
                    onChange={e => onUsersChange(parseInt(e.target.value))}
                />
                </PacketPrice>
            </Packet>
        case "promo":
            return <Packet type="Basic">
                <PacketPrice><span>99,00 PLN</span></PacketPrice>
                <PacketFooter><s>348,00 PLN</s> {formatString(msgs.commons.packetPriceDiscount, 72)}</PacketFooter>
            </Packet>
    }
}

const UserPaymentRow = ({ children }) => {
    return <Form.Row className="pb-3">
        <h4>{children}</h4>
    </Form.Row>
}

const UserPaymentSummary = ({ product, users, onUpdate }: {
    product: PacketProduct, users: number;
    onUpdate?: (cost: PacketPriceDetails) => void;
}) => {
    const msgs=useMsgs();
    const [ cost, setCost ]=useState<PacketPriceDetails|null>(null);
    const [ refreshing, setRefreshing ]=useState<boolean>(false);
    useEffect(() => {
        if(product!=="company_month" && product!=="company_year" && product!=="company_users") {
            setCost(null); setRefreshing(false);
            return;
        }
        setRefreshing(true);
        let handle:number|true|null;
        handle=window.setTimeout(() => {
            handle=true;
            userApi.getPacketPrice(product, users).then((cost: PacketPriceDetails) => {
                if(handle!==true) return;   // bo ignorowane dane
                setCost(cost);
                if(onUpdate) onUpdate(cost);
            }).finally(() => {
                setRefreshing(false);
            });
        }, 600);
        return () => {
            if(typeof(handle)==='number') window.clearTimeout(handle);
            handle=null;
        }
    }, [ product, users ])
    switch (product) {
        default:
        case "free_10_exports":
            return <UserPaymentRow>{msgs.commons.packetItemBuyExports}</UserPaymentRow>;
        case "basic_month":
            return <UserPaymentRow>29,00 PLN</UserPaymentRow>;
        case "basic_year":
            return <UserPaymentRow>261,00 PLN</UserPaymentRow>;
        case "promo":
            return <UserPaymentRow>99,00 PLN</UserPaymentRow>;
        case "company_month":
        case "company_year":
        case "company_users":
            return <>
                {cost && <Form.Row className="payment-info">
                    <Table size="sm" hover>
                        <thead>
                        <tr>
                            <th>{msgs.account.packetCompanyItem}</th><th className="text-right">{msgs.account.packetCompanyItemPrice}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {cost.core && <tr key="core-cost">
                            <td>{msgs.account.packetCompanyCore}</td>
                            <td className="money">{cost.core} PLN</td>
                        </tr>}
                        {cost.usersPrice && <tr key="core-users">
                            <td>{msgs.account.packetCompanyExtraUsers}<br/>
                                <small>{formatString(msgs.account.packetCompanyExtraUsersHint, cost.additionalUsers, cost.validityMonths)}</small>
                            </td>
                            <td className="money">{cost.usersPrice} PLN</td>
                        </tr>}
                        {cost.supplementUsersPrice && <tr key="supplement">
                            <td>{msgs.account.packetCompanySupplementUsers}<br/>
                                <small>{formatString(msgs.account.packetCompanySupplementUsersHint, cost.supplementUsers, cost.supplementMonths)}</small>
                            </td>
                            <td className="money">{cost.supplementUsersPrice} PLN</td>
                        </tr>}
                        </tbody>
                    </Table>
                </Form.Row>}
                <Form.Row className="payment-info pb-3 ">
                    {cost && cost.price && <h4 className="d-block">{formatString(msgs.account.packetCompanyTotal, cost.price)} PLN</h4>}
                    {refreshing && <Spinner className="ml-5" key="status" animation="border"/>}
                </Form.Row>

            </>
        // case "company_month":
        //     return "99,00 PLN";
        // case "company_year":
        //     return "891,00 PLN";
    }
}

/**
 * Nowy ekran do obsługi płatności/pakietów.
 */
const PacketScreen =() => {
    const { product } = useParams<{ product?: PacketProduct|"renew" }>();
    const [ priceMode, setPriceMode ] = useState<string>(() => {
        if(product==="basic_year" || product==="company_year") return "year";
        return "month";
    });
    const [ users, setUsers ] = useState<number>(null);
    const [ min, setMin ] = useState<number|null>(null);
    const [ cost, setCost ] = useState<null|PacketPriceDetails>(null);

    const changePriceMode=useCallback((e) => setPriceMode(e.target.value), [ setPriceMode ]);

    const handlePriceChange=useCallback((cost: PacketPriceDetails) => {
        setCost(cost);
        setMin((min) => (min===null || min<cost.minimalUsers)?cost.minimalUsers:min);
        setUsers((users) => (users===null)?cost.users:users)
    }, [ ]);
    const company=product==="company_year" || product==="company_month" || product==="company_users";

    const user=useUserSession();
    const msgs=useMsgs();
    const history=useHistory();

    const handleBuy=useCallback(async (value, helpers: FormikHelpers) => {
        // console.log("Handle buy: ", value);
        const res:string|ErrorResult=await userApi.startPayment({
            ...value,
            product: product,
            users: users,
        });
        if(Form.setError(helpers, res)) {
            return;
        }
        window.location=res;
    }, [product, users ]);


    if(user.type==="CompanyWorker") return <Redirect to="/"/>;   // konto pracownicze nie ma do tego dostępu
    if(product==="renew") { // szczególny link do przedłużenia abonamentu
        switch (user.type) {
            case "Basic":
                return <Redirect to={PacketScreen.link("basic_year")} push={false}/>;
            case "Company":
                return <Redirect to={PacketScreen.link("company_year")} push={false}/>;
            case "Free":
                return <Redirect to={PacketScreen.link("free_10_exports")} push={false}/>;
            default:
                return <Redirect to={PacketScreen.link()} push={false}/>
        }

    }
    const type:AccountType=user.type;

    if(product) { // tryb wybranego pakietu - zakup
        if(
            (product==="promo" && user.promo!==true) ||   // brak trybu promocyjnego
            (product==="free_10_exports" && type!=="Free")  || // dokupić eksporty można tylko dla darmowego konta
            ( (product==="basic_year" || product==="basic_month") && type==="Company") // jak jest konto firmowe, to nie kupić pakietu podstawowego
        ) return <Redirect to={PacketScreen.link()}/>;
        return <>
            <Helmet>
                <title>{msgs.account.packetTitle}</title>
            </Helmet>
            <h1>{msgs.account.packetTitle}</h1>
            <Row>
                <Col md={6}>
                    <UserProduct product={product} users={users} onUsersChange={setUsers} minUsers={min}/>
                </Col>
                <Col md={6}>
                    <PaymentForm required disabled={company && (!cost || !cost.price)}
                                 onSubmit={handleBuy} submit={msgs.account.paymentBuy}
                                 footer={(formik) => <UserPaymentSummary product={product} users={users} onUpdate={handlePriceChange}/>}
                    >{formik => <Form.RowFormMainError/>}</PaymentForm>
                </Col>
            </Row>
        </>
    }

    // Zwykłe konto - można wykupić pakiet, jak i przejść na pakiet biurowy
    return <>
        <Helmet>
            <title>{msgs.account.packetTitle}</title>
        </Helmet>
        <h1>{msgs.account.packetTitle}</h1>
        <h2 className="mb-4">{msgs.account.packetActiveLabel}: <span className="text-success">{msgs.account['packet'+type]}</span></h2>
        <UserPacketInfo/>
        <Row className="mt-5 mb-4">
            <Col md={4}>
                <Packet type="Free">
                    <PacketPrice>{msgs.commons.packetPriceFree}</PacketPrice>
                    <PacketFooter>{msgs.commons.packetPriceOneFree}</PacketFooter>
                    <PacketAction
                        history={history}
                        variant={type==="Free"?"secondary":"info"}
                        disabled={type!=="Free"}
                        href={type==="Free"?PacketScreen.link("free_10_exports"):"/"}
                    >{type==="Free"?msgs.commons.packetItemActionBuyExports:msgs.commons.packetActionUnavailable}</PacketAction>
                </Packet>
            </Col>
            <Col md={4}>
                <Packet type="Basic">
                    <PacketPrice><span>{priceMode==="month"?"29,00 PLN":"261,00 PLN"}</span><PriceMode value={priceMode} onChange={changePriceMode}/></PacketPrice>
                    <PacketFooter>{priceMode==="month"?null:<><s>348,00 PLN</s> {formatString(msgs.commons.packetPriceDiscount, 25)}</>}</PacketFooter>
                    <PacketAction
                        history={history}
                        disabled={type!=="Free" && type!=="Basic"}
                        href={PacketScreen.link("basic_"+priceMode)}
                        variant={type==="Free"?"primary":(type==="Basic"?"secondary":"info")}
                    >{type==="Free"?msgs.commons.packetActionUpgrade:(type==="Basic"?msgs.account.packetExtend:msgs.commons.packetActionUnavailable)}</PacketAction>
                </Packet>
            </Col>
            <Col md={4}>
                <Packet type="Company">
                    <PacketPrice><span>{priceMode==="month"?"99,00 PLN":"891,00 PLN"}</span><PriceMode value={priceMode} onChange={changePriceMode}/></PacketPrice>
                    <PacketFooter>{priceMode==="month"?<b>&nbsp;</b>:<><s>1188,00 PLN</s> {formatString(msgs.commons.packetPriceDiscount, 25)}</>}</PacketFooter>
                    <PacketAction
                        history={history}
                        href={PacketScreen.link("company_"+priceMode)}
                        variant={type==="Company"?"secondary":"primary"}
                    >{type==="Company"?msgs.account.packetExtend:msgs.commons.packetActionUpgrade}</PacketAction>
                    {/*<PacketAction*/}
                    {/*    history={history}*/}
                    {/*    disabled={type==="Company"}*/}
                    {/*    href={PacketScreen.link("company_"+priceMode)}*/}
                    {/*    variant={type!=="Company"?"primary":"secondary"}*/}
                    {/*>{type!=="Company"?msgs.commons.packetActionUpgrade:msgs.commons.packetActionActive}</PacketAction>*/}
                </Packet>
            </Col>
        </Row>
    </>
}
PacketScreen.url=[ "/packet", "/packet/:product" ];
PacketScreen.link=(prod?: PacketProduct) => prod?"/packet/"+prod:"/packet";
PacketScreen.promoLink="/packet/promo";
export default PacketScreen;

